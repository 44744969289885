@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Saira:300,500");
. bbg-white{
    background-color: white;
}
.panel-bg{
    background-image: url("../assets/panel-bg.jpg") ;
    background-size: cover;
    background-repeat: no-repeat;
}
.MTableHeader-header-13 {
    z-index:1!important;
}
.jss13{
    z-index:1!important;
}
.rbc-toolbar {
    font-size: 14px!important;
}
.bbg-white{
    background-color: white;
    color: #0D0A0A;
}

.dropdown-menu li {
    position: relative;
}
.dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
    display: block;
}

body{
    overflow-x: hidden;
}
.gradient-card{
    /*background:linear-gradient(135deg, #fad961 0%,#fb8a00 100%);*/
    background:linear-gradient(135deg, #17ead9 0%,#6078ea 100%);
    font-weight: bold;
    color: white;
}

h1,h2,h3,h4,h5{
    font-family: 'Roboto Slab', serif!important;
    font-weight: bolder;
}
.headlg{
    font-family: 'Roboto Slab', serif!important;
}

.ttb{
    color:black!important;
}

.badgeButton:hover{
  cursor: pointer;
    border: darkgrey 1px solid;

}
.badgeButton{
    cursor: pointer;
    border: rgba(0,0,0,0) 1px solid;

}

  a li span {
    color: #494b52;
    font-size:0.8em;
     
}
a li i {
    font-size:0.8em;
    color: black!important;   
}
a li  {
    font-size:15px;
    padding: 2px;
}
.subjectRow:hover{
    background-color: white!important;
}
.subjectRow.active{
    background-color: white!important;
}

.dptMenu{
    padding: 5px 20px;
    cursor: pointer;
}
.dptMenu:hover{
    font-weight: bold!important;
}
.dptMenu.active{
    cursor: pointer;

    font-weight: bold!important;
    border-left: solid 4px #ff0101 ;
}

.HOME,.CALENDAR,.SUPPORT
,.ADD_USER,.REGISTER,.PROFILE,
.ARREARS,.SPORTS,.CLUBS,
.DEPARTMENTS,.CONTACTS,
.MEDICALS,.STUDENTSCOMMENTS,
.MANAGECALENDAR,.MANAGENOTIFICATIONS,.TEACHERS,.SCHEMA{
    color: black;
    font-weight:bold;
    /* fallback for old browsers */
     background-color: #F6F6F6;
   border-right: solid 4px #ff0101 ;
}

.PORTAL ,.ADD_PORTAL_USER{
    color: black;
    font-weight:bold;
    /* fallback for old browsers */
     background-color: #F6F6F6;
   border-right: solid 4px orangered ;
}

body {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ebeeef;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    color: #494b52;
    letter-spacing: 0.3px
}

a:hover,
a:focus {
    color: #437ac7;
    text-decoration: none
}

.overlay {
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(45, 53, 65, 0.5);
    display: none;
    z-index: 10
}

.overlay.open {
    display: block
}

@media screen and (max-width: 767px) {
    .hidden-xs {
        display: none
    }
}

@media screen and (max-width: 992px) {
    .hidden-sm {
        display: none
    }
}

@media screen and (max-width: 1024px) {
    .hidden-md {
        display: none
    }
}

#main-content {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
     width: calc(100% - 290px); 
    float: right;
    position: relative;
    margin-top: 60px;
    padding: 0 10px
}

@media screen and (max-width: 1200px) {
    #main-content {
        width: 100% ;
        padding: 0
    }
}

@media screen and (max-width: 768px) {
    #main-content {
        margin-top: 52px
    }
}

html.fullscreen-bg,
html.fullscreen-bg body,
html.fullscreen-bg #wrapper {
    height: 100%
}

.vertical-align-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: table
}

.vertical-align-middle {
    display: table-cell;
    vertical-align: middle
}

.small_menu #left-sidebar {
    width: 0px
}

.small_menu #main-content {
    width: 100%
}

.small_menu .sidebar_list {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: none
}

@media screen and (max-width: 1200px) {
    .small_menu #left-sidebar .sidebar_list {
        -moz-box-shadow: 0px 2px 10px 0px rgba(45, 53, 65, 0.2);
        -webkit-box-shadow: 0px 2px 10px 0px rgba(45, 53, 65, 0.2);
        box-shadow: 0px 2px 10px 0px rgba(45, 53, 65, 0.2);
        display: block
    }
}

@media screen and (max-width: 767px) {
    .card .body {
        padding: 15px
    }
}

.card .card-footer {
    border-color: rgba(255, 255, 255, 0.03);
    background-color: rgba(255, 255, 255, 0.05)
}

@media screen and (max-width: 1024px) {
    .btn-toggle-fullwidth {
        display: none
    }
}
.zimra-title{
    color: white!important;

    font-size:small;
    
}
.zimra-tool{
    color: #ff0101 !important;
   opacity: 0.6;
    font-size:smaller;
    
}
.zimra-menu{
    color: #ff0101;
   
   
    
}
.zimra-menu :hover{
    color: white;
  
   cursor: pointer;
  
    
}
.navbar-fixed-top {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: #5e0c0c;  /* fallback for old browsers */
    position: fixed;
    top: 0px;
    right: 0;
    width: 100%;
    z-index: 12;
    padding: 5px 0;
  
   
}

.navbar-fixed-top>.container-fluid {
    padding: 0 10px
}

@media screen and (max-width: 1200px) {
    .navbar-fixed-top {
        width: 100%;
        margin: 0
    }
}

.navbar-fixed-top .navbar-brand {
    padding: 0;
    float: left
}

.navbar-fixed-top .navbar-brand .logo {
    width: 25px
}

.navbar-fixed-top .navbar-brand span {
    color: #fff
}

.navbar-fixed-top .navbar-brand .small_menu_btn {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    padding: 0;
    color: #fff;
    opacity: 0.4
}

.navbar-fixed-top .navbar-brand .small_menu_btn:hover {
    opacity: 1
}

@media screen and (max-width: 1200px) {
    .navbar-fixed-top .navbar-brand {
        display: block
    }
}

@media screen and (max-width: 767px) {
    .navbar-fixed-top .navbar-brand {
        margin-left: 0;
        margin-right: 0 !important
    }
}

.navbar-fixed-top .navbar-right {
    display: inline-flex
}

.navbar-fixed-top .btn-toggle-fullwidth {
    float: left
}

.navbar-fixed-top #navbar-search {
    float: left;
    position: relative
}

.navbar-fixed-top #navbar-search .form-control {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: rgba(255, 255, 255, 0.06);
    width: 750px;
    height: 33px;
    border: none
}

@media screen and (max-width: 1600px) {
    .navbar-fixed-top #navbar-search .form-control {
        width: 600px
    }
}

@media screen and (max-width: 1280px) {
    .navbar-fixed-top #navbar-search .form-control {
        width: 400px
    }
}

@media screen and (max-width: 1024px) {
    .navbar-fixed-top #navbar-search .form-control {
        width: 250px
    }
}

.navbar-fixed-top #navbar-search .btn {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #77797c;
    position: absolute;
    top: 6px;
    right: 15px;
    border: none;
    background-color: transparent
}

.navbar-fixed-top #navbar-search .btn:hover,
.navbar-fixed-top #navbar-search .btn:focus {
    background-color: inherit;
    border-left: none;
    border-color: inherit
}

@media screen and (max-width: 767px) {
    .navbar-fixed-top #navbar-search {
        display: none
    }
}

.navbar-nav {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline
}

.navbar-nav>li {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    vertical-align: middle
}

.navbar-nav>li a {
    color: #fff
}

.navbar-nav .dropdown-menu {
    -moz-box-shadow: 0px 2px 20px 0px rgba(45, 53, 65, 0.2);
    -webkit-box-shadow: 0px 2px 20px 0px rgba(45, 53, 65, 0.2);
    box-shadow: 0px 2px 20px 0px rgba(45, 53, 65, 0.2);
    border-radius: .55rem;
    border: 0;
    top: 100%;
    position: absolute;
    font-size: inherit;
    overflow: hidden;
    padding: 0 0 20px 0;
    right: 0;
    left: auto
}

.navbar-nav .dropdown-menu li.header {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(45, 53, 65, 0.05);
    position: relative;
    display: flex;
    height: 80px;
    align-items: center;
    color: #fff
}

.navbar-nav .dropdown-menu li.header::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1
}

.navbar-nav .dropdown-menu li:hover {
    background: rgba(45, 53, 65, 0.07)
}

.navbar-nav .icon-menu {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    color: rgba(255, 255, 255, 0.4);
    padding: 12px 15px;
    position: relative
}

.navbar-nav .icon-menu:hover,
.navbar-nav .icon-menu.active {
    color: #fff
}

.navbar-nav .icon-menu::after {
    display: none
}

.navbar-nav .icon-menu i {
    vertical-align: middle
}

.navbar-nav .icon-menu .notification-dot {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    width: 5px;
    height: 5px;
    line-height: 14px;
    position: absolute;
    top: 12px;
    right: 11px;
    text-align: center;
    font-size: 8px;
    color: #fff
}

.navbar-nav ul.email {
    width: 360px;
    list-style: none
}

.navbar-nav ul.email li a {
    padding: 15px 25px;
    display: block
}

.navbar-nav ul.email li a .media {
    margin-bottom: 0
}

.navbar-nav ul.email.right_chat .media .name small {
    color: #96989c
}

@media screen and (max-width: 768px) {
    .navbar-nav ul.email {
        width: calc(100% - 20px);
        margin: 0 10px
    }
}

.navbar-nav ul.feeds_widget {
    width: 360px
}

@media screen and (max-width: 768px) {
    .navbar-nav ul.feeds_widget {
        width: calc(100% - 20px);
        margin: 0 10px
    }
}

@media screen and (max-width: 767px) {
    .navbar-nav {
        margin-right: 0
    }

    .navbar-nav>li {
        position: inherit;
        text-align: center
    }

    .navbar-nav .dropdown-menu {
        max-height: 400px;
        overflow: auto
    }

    .navbar-nav .dropdown-menu>li>a {
        white-space: normal
    }

    .navbar-nav .open .dropdown-menu {
        -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
        position: absolute;
        width: 100%;
        background: #fff
    }

    .navbar-nav .open .dropdown-menu>li>a {
        padding: 3px 20px
    }

    .navbar-nav .open .dropdown-menu.notifications>li>a {
        padding: 12px 15px
    }

    .navbar-nav .news,
    .navbar-nav .megamenu_toggle {
        display: none
    }

    .navbar-nav .reports {
        display: none
    }
}

@media screen and (max-width: 992px) {

    .navbar-nav .project,
    .navbar-nav .settings {
        display: none
    }
}

@media screen and (max-width: 768px) {

    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: 0
    }
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
    border-color: #ddd
}


#left-sidebar {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 290px;
    background: #fff;
    top: 60px;
    height: calc(100vh - 60px);
    float: left;
    position: fixed;
    left: 0px;
    z-index: 9;
    display: flex
}

@media screen and (max-width: 1200px) {
    #left-sidebar {
        width: 0px
    }
}

#left-sidebar .sidebar-nav {
    padding: 15px 0  15px 15px 
}

#left-sidebar .sidebar-nav ul {
    list-style: none
}

.sidebar_icon {
    width: 45px
}

.sidebar_icon .nav-tabs {
    border: 0
}

.sidebar_icon .nav-tabs .nav-item {
    margin: 0
}

.sidebar_icon .nav-tabs .nav-item .nav-link {
    color: #fff;
    background: transparent;
    border: 0;
    border-radius: 0;
    width: 45px;
    line-height: 45px;
    text-align: center;
    padding: 0
}

.sidebar_icon .nav-tabs .nav-item .nav-link.active,
.sidebar_icon .nav-tabs .nav-item .nav-link:hover,
.sidebar_icon .nav-tabs .nav-item .nav-link:focus {
    background: rgba(255, 255, 255, 0.1)
}

.sidebar_icon .nav-tabs li:last-child {
    position: absolute;
    bottom: 0
}

.sidebar_list {
    width: 100%
}

@media screen and (max-width: 1200px) {
    .sidebar_list {
        position: fixed;
        left: 45px;
        background: #fff;
        width: 250px;
        display: none
    }
}

.sidebar_list .tab-content {
    margin: 0
}

.sidebar_list.dark_menu {
    background: #2D3541;
    color: #96989c
}

.sidebar_list.dark_menu .metismenu a {
    color: #96989c
}

.sidebar_list.dark_menu .metismenu li.header {
    color: #96989c
}

.sidebar_list.dark_menu .fancy-checkbox input[type="checkbox"]+span:before {
    border: 1px solid rgba(255, 255, 255, 0.3)
}

.sidebar_list.dark_menu .progress {
    background-color: rgba(255, 255, 255, 0.06)
}

.rightbar {
    -moz-box-shadow: 0 5px 10px 0px rgba(45, 53, 65, 0.3);
    -webkit-box-shadow: 0 5px 10px 0px rgba(45, 53, 65, 0.3);
    box-shadow: 0 5px 10px 0px rgba(45, 53, 65, 0.3);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: #fff;
    position: fixed;
    right: -420px;
    top: 0;
    width: 340px;
    height: 100vh;
    z-index: 13;
    padding: 15px 20px
}

@media screen and (max-width: 1200px) {
    .rightbar {
        width: 280px
    }
}

.rightbar.open {
    right: 0
}

.rightbar .chat_detail {
    height: calc(100vh - 50px);
    overflow-y: auto;
    display: block;
    position: relative
}

.rightbar .chat_detail .input-group {
    position: absolute;
    bottom: 0
}

ul.choose-skin {
    display: flex;
    justify-content: space-between
}

ul.choose-skin li div {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    height: 35px;
    width: 35px;
    overflow: hidden;
    position: relative
}

ul.choose-skin li div.blue {
    background: #36c2cf
}

ul.choose-skin li div.green {
    background: #5CB65F
}

ul.choose-skin li div.orange {
    background: #FFA117
}

ul.choose-skin li div.cyan {
    background: #36c2cf
}

ul.choose-skin li div.blush {
    background: #e25985
}

ul.choose-skin li.active div::after {
    position: absolute;
    font-size: 14px;
    color: #fff;
    top: 7px;
    left: 11px;
    content: '\f087';
    font-family: 'FontAwesome'
}

.form-control {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    font-size: 15px;
    color: #77797c
}

.form-control.round {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px
}

.form-control:focus {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    outline: none
}

.form-control.parsley-error:focus {
    border-color: #e1b3b3
}

.form-control.parsley-success:focus {
    border-color: #afd99d
}

.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
    background: transparent;
    color: #77797c;
    font-size: 15px
}

.input-group-prepend .btn-outline-secondary,
.input-group-append .btn-outline-secondary {
    border-color: #ced4da
}

.custom-select {
    background-color: transparent;
    border-color: #e2e4e7
}

.custom-select:focus {
    outline: none;
    color: #494b52;
    background: #fff
}

.custom-file .custom-file-label {
    background: transparent;
    border-color: #e2e4e7
}

.custom-file .custom-file-label:focus {
    outline: none;
    color: #494b52;
    background: #e2e4e7
}

.fancy-checkbox {
    margin: 0
}

.fancy-checkbox,
.fancy-checkbox label {
    font-weight: normal
}

.fancy-checkbox input[type="checkbox"] {
    display: none
}

.fancy-checkbox input[type="checkbox"]+span {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    cursor: pointer;
    position: relative
}

.fancy-checkbox input[type="checkbox"]+span:before {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
    bottom: 1px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    content: "";
    border: 1px solid rgba(45, 53, 65, 0.2)
}

.fancy-checkbox input[type="checkbox"]:checked+span:before {
    font-family: 'FontAwesome';
    content: '\f0c8';
    color: #2D3541;
    line-height: 17px
}

.fancy-checkbox.custom-color-green input[type="checkbox"]:checked+span:before {
    color: #5CB65F;
    background-color: #fff
}

.fancy-checkbox.custom-bgcolor-green input[type="checkbox"]:checked+span:before {
    color: #fff;
    background-color: #5CB65F;
    border-color: #48a14b
}

.fancy-radio {
    margin-right: 10px
}

.fancy-radio,
.fancy-radio label {
    font-weight: normal
}

.fancy-radio input[type="radio"] {
    display: none
}

.fancy-radio input[type="radio"]+span {
    display: block;
    cursor: pointer;
    position: relative
}

.fancy-radio input[type="radio"]+span i {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    position: relative;
    bottom: 1px;
    content: "";
    border: 1px solid rgba(45, 53, 65, 0.2);
    width: 18px;
    height: 18px;
    margin-right: 5px
}

.fancy-radio input[type="radio"]:checked+span i:after {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: block;
    position: relative;
    top: 3px;
    left: 3px;
    content: '';
    width: 10px;
    height: 10px;
    background-color: #7b848c
}

.fancy-radio.custom-color-green input[type="radio"]:checked+span i:after {
    background-color: #5CB65F
}

.fancy-radio.custom-bgcolor-green input[type="radio"]:checked+span i {
    background-color: #5CB65F
}

.fancy-radio.custom-bgcolor-green input[type="radio"]:checked+span i:after {
    background-color: #fff
}

.input-group-addon .fancy-radio,
.input-group-addon .fancy-checkbox {
    margin: 0;
    position: relative;
    top: 1px
}

.input-group-addon .fancy-radio input[type="radio"]+span i,
.input-group-addon .fancy-checkbox input[type="checkbox"]+span {
    margin: 0
}

.input-group-addon .fancy-radio input[type="radio"]+span i:before,
.input-group-addon .fancy-checkbox input[type="checkbox"]+span:before {
    margin: 0
}

.help-block {
    font-size: 13px;
    color: #929292
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a {
    padding: 5px 15px;
    display: block;
    outline: none
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox {
    padding: 0
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox:before {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
    bottom: 1px;
    content: "";
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 1px solid #ccc;
    background-color: #fafafa
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox input[type="checkbox"] {
    display: none
}

.multiselect-custom+.btn-group ul.multiselect-container>li.active>a label.checkbox:before {
    font-family: 'FontAwesome';
    content: '\f00c';
    color: #99A1A7;
    line-height: 15px;
    background-color: #e2e4e7
}

.multiselect-custom+.btn-group ul.multiselect-container>li.active .checkbox {
    color: #fff
}

.multiselect-custom+.btn-group ul.multiselect-container>li:hover {
    background-color: #f7f7f7
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a {
    padding: 5px 15px;
    display: block;
    outline: none
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.radio {
    padding: 0
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.radio:before {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    position: relative;
    bottom: 1px;
    content: "";
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 1px solid #ccc;
    background-color: #fafafa
}

.multiselect-custom+.btn-group ul.multiselect-container>li>a label.radio input[type="radio"] {
    display: none
}

.multiselect-custom+.btn-group ul.multiselect-container>li.active>a label.radio:before {
    font-family: 'FontAwesome';
    content: '\f111';
    color: #99a1a7;
    line-height: 15px
}

.multiselect-custom+.btn-group ul.multiselect-container>li.active .radio {
    color: #fff
}

.multiselect-custom+.btn-group ul.multiselect-container>li:hover {
    background-color: #f7f7f7
}

.multiselect-search {
    -moz-border-radius-topright: 2px !important;
    -webkit-border-top-right-radius: 2px !important;
    border-top-right-radius: 2px !important;
    -moz-border-radius-bottomright: 2px !important;
    -webkit-border-bottom-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important
}

.btn.multiselect-clear-filter {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: absolute;
    top: 2px;
    right: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background: transparent;
    z-index: 9 !important;
    outline: none
}

.btn.multiselect-clear-filter:hover,
.btn.multiselect-clear-filter:focus {
    background: transparent;
    outline: none
}

.multiselect_div>.btn-group {
    display: block
}

.multiselect_div>.btn-group .btn {
    text-align: left;
    position: relative;
    width: 100%;
    border: 1px solid #ced4da;
    background: transparent
}

.multiselect_div>.btn-group .btn:hover {
    box-shadow: none
}

.multiselect_div>.btn-group .btn:after {
    position: absolute;
    right: 15px;
    top: 15px
}

.multiselect_div>.btn-group ul {
    width: 100%
}

.datepicker {
    padding: 10px;
    font-size: 14px
}

.datepicker.datepicker-inline {
    width: 320px
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active:active {
    background-color: #5c8ed4;
    border-color: #3c78cb
}

.datepicker table tr td,
.datepicker table tr th {
    width: 40px;
    height: 40px
}

.range-to {
    padding: 4px 10px !important;
    background: #ced4da;
    margin: 0 !important
}

.parsley-errors-list {
    margin-top: 8px
}

.parsley-errors-list li {
    color: #E15858;
    font-size: 0.9em;
    margin-top: 3px
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error {
    background-color: #fbf5f5;
    border-color: #efd8d8
}

.md-editor>textarea {
    padding: 10px
}

.dropify-wrapper {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #ebeeef;
    color: #b9b9b9;
    border: 1px solid #ced4da
}

.dropify-wrapper:hover {
    background-image: linear-gradient(-45deg, #ced4da 20%, transparent 25%, transparent 50%, #ced4da 45%, #ced4da 70%, transparent 75%, transparent)
}

.dropify-wrapper .dropify-preview {
    background-color: #ebeeef
}

select.form-control:not([size]):not([multiple]) {
    height: 36px
}

.auth-main {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: center;
    height: 100vh
}

.auth-main .auth_div {
    width: 100%;
    z-index: 999;
    height: 100vh;
    display: flex;
    align-items: center;
    background: #fff
}

@media screen and (max-width: 767px) {
    .auth-main .auth_div {
        width: 320px
    }
}

.auth-main .auth_div .card {
    overflow: hidden;
    border: 0;
    box-shadow: none;
    margin: 0 auto;
    width: 360px
}

.auth-main .auth_right {
    width: 500px;
    color: #fff
}

.auth-main .form-auth-small .element-left {
    float: left
}

.auth-main .form-auth-small .element-right {
    float: right
}

.auth-main .lead {
    color: #494b52
}

.auth-main .helper-text {
    color: #9A9A9A;
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block
}

.auth-main .btn-signin-social {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    background-color: #fff;
    border-color: #e2e4e7;
    font-weight: 400;
    padding: 10px 22px;
    width: 100%
}

.auth-main .btn-signin-social:hover,
.auth-main .btn-signin-social:focus {
    background-color: #e2e4e7
}

.auth-main .btn-signin-social i {
    margin: 5px
}

.auth-main .page-400::before,
.auth-main .forgot-pass::before {
    z-index: 1
}

.auth-main .page-400 .header,
.auth-main .page-400 .body,
.auth-main .forgot-pass .header,
.auth-main .forgot-pass .body {
    z-index: 9;
    position: relative
}

.auth-main .page-400 {
    position: relative
}

.auth-main .page-400::before {
    content: '404';
    font-size: 100px;
    top: 20px;
    position: absolute;
    left: 80px;
    opacity: 0.07
}

.auth-main .forgot-pass {
    position: relative
}

.auth-main .forgot-pass::before {
    content: '\e08e';

    font-size: 290px;
    top: -80px;
    position: absolute;
    left: 38px;
    opacity: 0.03
}

@media screen and (max-width: 992px) {
    .auth-main {
        margin: 0 auto
    }
}

.btn {
    font-size: 15px;
    font-weight: 300
}

.btn.btn-round {
    border-radius: 50px;
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.metric-inline {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 20px;
    font-size: 36px;
    background-color: #f5f5f5
}

.metric-inline i {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    margin-bottom: 12px;
    color: #9A9A9A
}

.metric-inline span {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    font-size: 20px
}

.separator-linethrough {
    position: relative;
    margin: 30px 0
}

.separator-linethrough span {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    padding: 0 10px;
    position: inherit;
    background-color: #fff;
    z-index: 9
}

.separator-linethrough:after {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    content: '';
    border-top: 1px solid #ced4da;
    z-index: 0
}

.block-header {
    padding: 0 15px
}

@media screen and (max-width: 640px) {
    .block-header .text-right {
        text-align: left !important
    }
}

.block-header h2,
.block-header h1 {
    line-height: 28px;
    color: #2D3541
}

.block-header .breadcrumb {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    background: transparent
}

.block-header .breadcrumb li {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline
}

#toast-container>div {
    opacity: 1;
    filter: alpha(opacity=100)
}

#toast-container>div,
#toast-container>div:hover {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

#toast-container .toast-close-button {
    opacity: .3;
    filter: alpha(opacity=30);
    top: -11px;
    text-shadow: none;
    color: #fff
}

#toast-container .toast-close-button:hover,
#toast-container .toast-close-button:focus {
    opacity: .5;
    filter: alpha(opacity=50)
}

#toast-container .toast-info {
    background-color: #5c8ed4
}

#toast-container .toast-info .toast-close-button {
    color: #2a5ba0
}

#toast-container .toast-success {
    background-color: #5CB65F
}

#toast-container .toast-success .toast-close-button {
    color: #357737
}

#toast-container .toast-warning {
    background-color: #FFA117
}

#toast-container .toast-warning .toast-close-button {
    color: #b06900
}

#toast-container .toast-error {
    background-color: #E15858
}

#toast-container .toast-error .toast-close-button {
    color: #9d1c1c
}

#toast-container .toast a {
    text-decoration: underline
}

#toast-container .toast a:hover,
#toast-container .toast a:focus {
    text-decoration: none;
    color: #fff
}

#toast-container .toast .toast-message {
    font-size: 14px;
    line-height: 1.4
}

.accordion>div {
    background: #ebeeef;
    border-radius: .1875rem
}

.accordion .card-header .btn {
    color: #212834;
    padding: 10px 20px;
    width: 100%
}

@media screen and (max-width: 767px) {
    .accordion .card-header .btn {
        white-space: normal
    }
}

.accordion .card-header .btn:hover,
.accordion .card-header .btn:focus {
    box-shadow: none;
    text-decoration: none
}

.accordion.custom .card-header {
    background: transparent;
    border-color: #e2e4e7
}

.accordion.custom .card-header .btn {
    color: #393d42
}

.accordion.custom .card-header .btn:hover,
.accordion.custom .card-header .btn:focus {
    text-decoration: none
}

.accordion.custom .card-body {
    font-size: 15px;
    line-height: 28px;
    color: #57595d
}

.accordion2 {
    list-style: none;
    border-top: 1px solid #ced4da
}

.accordion2 .accordion-item {
    border-bottom: 1px solid #ced4da;
    padding: 0 10px
}

.accordion2 .accordion-thumb {
    font-weight: 400;
    padding: .8rem 0;
    cursor: pointer
}

.accordion2 .accordion-thumb::before {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    content: "\f138";
    font-family: 'FontAwesome'
}

.accordion2 .accordion-panel {
    display: none
}

.accordion2 .accordion-item.is-active .accordion-thumb::before {
    content: "\f13a"
}

.progress {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: rgba(45, 53, 65, 0.06)
}

.progress.wide {
    width: 60px
}

.progress .progress-bar {
    -webkit-transition: width 3s ease;
    -moz-transition: width 3s ease;
    -ms-transition: width 3s ease;
    -o-transition: width 3s ease;
    transition: width 3s ease;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.progress.progress-lg {
    height: 20px
}

.progress.progress-sm {
    height: 10px
}

.progress.progress-xs {
    height: 5px
}

.progress.progress-xxs {
    height: 1px
}

.progress.progress-sm .progress-bar,
.progress.progress-xs .progress-bar {
    text-indent: -9999px
}

.progress-bar {
    background-color: #5c8ed4
}

.progress-bar-success {
    background-color: #5CB65F
}

.progress-bar-warning {
    background-color: #FFA117
}

.progress-bar-danger {
    background-color: #E15858
}

.progress-bar-info {
    background-color: #3C89DA
}

.progress-transparent.custom-color-blue {
    background-color: rgba(86, 138, 211, 0.2)
}

.progress-transparent.custom-color-blue .progress-bar {
    background-color: #568AD3
}

.progress-transparent.custom-color-green {
    background-color: rgba(92, 182, 95, 0.2)
}

.progress-transparent.custom-color-green .progress-bar {
    background-color: #5CB65F
}

.progress-transparent.custom-color-orange {
    background-color: rgba(255, 161, 23, 0.2)
}

.progress-transparent.custom-color-orange .progress-bar {
    background-color: #FFA117
}

.progress-transparent.custom-color-purple {
    background-color: rgba(171, 125, 246, 0.2)
}

.progress-transparent.custom-color-purple .progress-bar {
    background-color: #AB7DF6
}

.progress-transparent.custom-color-yellow {
    background-color: rgba(243, 187, 35, 0.2)
}

.progress-transparent.custom-color-yellow .progress-bar {
    background-color: #F3BB23
}

.progress-transparent.custom-color-lightseagreen {
    background-color: rgba(32, 178, 170, 0.2)
}

.progress-transparent.custom-color-lightseagreen .progress-bar {
    background-color: #20b2aa
}

.tooltip-inner {
    background-color: #212834
}

.tooltip.in {
    opacity: 1;
    filter: alpha(opacity=100)
}

.tooltip.left .tooltip-arrow {
    border-left-color: #212834
}

.tooltip.right .tooltip-arrow {
    border-right-color: #212834
}

.tooltip.top .tooltip-arrow {
    border-top-color: #212834
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #212834
}

.popover {
    -moz-box-shadow: 0px 2px 6px 0px rgba(45, 53, 65, 0.05);
    -webkit-box-shadow: 0px 2px 6px 0px rgba(45, 53, 65, 0.05);
    box-shadow: 0px 2px 6px 0px rgba(45, 53, 65, 0.05);
    font-family: "Raleway", sans-serif;
    border-color: #ededed
}

.popover-title {
    font-weight: 600;
    background-color: #f4f7f6;
    color: #494b52
}

.panel-group .panel .panel-heading {
    padding: 0
}

.panel-group .panel .panel-title {
    font-family: "Raleway", sans-serif
}

.panel-group .panel .panel-title>a {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    padding: 10px 15px
}

.panel-group .panel .panel-title>a:hover,
.panel-group .panel .panel-title>a:focus {
    color: inherit
}

.panel-group .panel .panel-title>a i.icon-collapsed {
    display: none
}

.panel-group .panel .panel-title>a i.right {
    float: right
}

.panel-group .panel .panel-title>a.collapsed .icon-expanded {
    display: none
}

.panel-group .panel .panel-title>a.collapsed .icon-collapsed {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    vertical-align: baseline
}

.pagination>li>a,
.pagination>li>span {
    color: #5c8ed4
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
    background-color: #7ca4dd;
    border-color: #5c8ed4;
    color: #fff
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-bottomleft: 2px;
    -webkit-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomright: 2px;
    -webkit-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px
}

.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #7ca4dd;
    border-color: #5c8ed4;
    color: #fff
}

.pagination>.active>a,
.pagination>.active>span {
    background-color: #5c8ed4;
    border-color: #4880cf
}

.pagination.borderless>li>a,
.pagination.borderless>li>span {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border-color: transparent;
    margin: 0 1px
}

.pagination.borderless>li:first-child>a,
.pagination.borderless>li:first-child>span,
.pagination.borderless>li:last-child>a,
.pagination.borderless>li:last-child>span {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px
}

.pagination.borderless>li.active>a,
.pagination.borderless>li.active>span {
    color: #fff;
    background-color: #5c8ed4
}

.pagination.borderless>li.active>a:hover,
.pagination.borderless>li.active>a:focus,
.pagination.borderless>li.active>span:hover,
.pagination.borderless>li.active>span:focus {
    color: #fff;
    background-color: #5c8ed4
}

.pagination .page-link {
    background-color: transparent;
    border-color: #ced4da
}

.pagination .disabled.page-item .page-link {
    background-color: #ced4da;
    border-color: #ced4da
}

.pager li>a {
    border-color: #5c8ed4
}

.pager li>a:hover,
.pager li>a:focus {
    background-color: #5c8ed4;
    border-color: #5086d1;
    color: #fff
}

.pager .disabled>a,
.pager .disabled>a:hover,
.pager .disabled>a:focus,
.pager .disabled>span {
    border-color: #bbbec2
}

.blockquote {
    border-left: none;
    border: 1px solid #57595d;
    padding: 20px;
    font-size: 1.2em;
    line-height: 1.8
}

.blockquote.blockquote-primary {
    border-color: #36c2cf
}

.blockquote.blockquote-primary small {
    color: #36c2cf
}

.blockquote.blockquote-info {
    border-color: #3C89DA
}

.blockquote.blockquote-info small {
    color: #3C89DA
}

.blockquote.blockquote-danger {
    border-color: #E15858
}

.blockquote.blockquote-danger small {
    color: #E15858
}

.blockquote small {
    font-size: .93em;
    text-transform: uppercase
}

.btn {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.btn:hover {
    -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2)
}

.btn.btn-default {
    color: #77797c;
    background-color: #fff;
    border-color: #f3f4f6;
    font-weight: 400;
    padding: 6px 18px
}

.btn.btn-default.btn-sm {
    padding: 4px 10px
}

.btn.btn-default:hover {
    color: #77797c;
    border-color: #77797c
}

.list-group-item.disabled {
    background-color: #ebeeef;
    color: #393d42
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    background-color: #ebeeef
}

.modal-dialog .close {
    text-shadow: none;
    color: #E15858
}

.modal-dialog .modal-content {
    background: #fff
}

.modal-dialog .modal-content .modal-header,
.modal-dialog .modal-content .modal-footer {
    border-color: #ced4da
}

.custom-file-label:after {
    background-color: #ced4da;
    border-color: #ced4da
}

.noUi-target {
    border-color: #ced4da;
    box-shadow: none
}

.noUi-target.noUi-connect,
.noUi-target .noUi-connect {
    background: #FFA117
}

.noUi-target .noUi-background {
    background: #ced4da;
    box-shadow: none
}

.noUi-target .noUi-handle {
    background: #5CB65F;
    box-shadow: none
}

.bd-example-border-utils [class^=border] {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    width: 5rem;
    height: 5rem;
    margin: .25rem;
    background-color: #f5f5f5
}

.carousel.vert .carousel-item-next.carousel-item-left,
.carousel.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.carousel.vert .carousel-item-next,
.carousel.vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100% 0)
}

.carousel.vert .carousel-item-prev,
.carousel.vert .active.carousel-item-left {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
}

.modal-dialog .close {
    color: #e25985
}

.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 22px;
    font-size: 0
}

.switch input {
    display: none
}

.switch .slider {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background-color: rgba(45, 53, 65, 0.1);
    border-radius: .1875rem;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.switch .slider:before {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background-color: #fff;
    border-radius: .1875rem;
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px
}

.switch input:checked+.slider {
    background-color: #5CB65F
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px #5CB65F
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px)
}

.switch .slider.round {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px
}

.switch .slider.round:before {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%
}

#jqstooltip {
    background-color: #fff;
    border-color: #ddd;
    color: #494b52
}

#jqstooltip .jqsfield {
    color: #494b52
}

.ct-chart {
    position: relative
}

.ct-series .ct-line {
    stroke-width: 2px
}

.ct-series .ct-bar {
    stroke-width: 15px
}

.ct-series .ct-point {
    stroke-width: 5px;
    stroke-linecap: circle
}

.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-point {
    stroke: #36c2cf
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-slice-pie,
.ct-series-a .ct-bar {
    fill: #36c2cf
}

.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-point {
    stroke: #5978ec
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-slice-pie,
.ct-series-b .ct-bar {
    fill: #5978ec
}

.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-point {
    stroke: #f66d9b
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-slice-pie,
.ct-series-c .ct-bar {
    fill: #f66d9b
}

.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-point {
    stroke: #ff9f24
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-donut-solid,
.ct-series-d .ct-slice-pie,
.ct-series-d .ct-bar {
    fill: #ff9f24
}

.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-point {
    stroke: #ab8ce4
}

.ct-series-e .ct-area,
.ct-series-e .ct-slice-donut-solid,
.ct-series-e .ct-slice-pie,
.ct-series-e .ct-bar {
    fill: #ab8ce4
}

.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-point {
    stroke: #d17905
}

.ct-series-f .ct-area,
.ct-series-f .ct-slice-donut-solid,
.ct-series-f .ct-slice-pie,
.ct-series-f .ct-bar {
    fill: #d17905
}

.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-point {
    stroke: #453d3f
}

.ct-series-g .ct-area,
.ct-series-g .ct-slice-donut-solid,
.ct-series-g .ct-slice-pie,
.ct-series-g .ct-bar {
    fill: #453d3f
}

.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-point {
    stroke: #59922b
}

.ct-series-h .ct-area,
.ct-series-h .ct-slice-donut-solid,
.ct-series-h .ct-slice-pie,
.ct-series-h .ct-bar {
    fill: #59922b
}

.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-point {
    stroke: #0544d3
}

.ct-series-i .ct-area,
.ct-series-i .ct-slice-donut-solid,
.ct-series-i .ct-slice-pie,
.ct-series-i .ct-bar {
    fill: #0544d3
}

.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-point {
    stroke: #6b0392
}

.ct-series-j .ct-area,
.ct-series-j .ct-slice-donut-solid,
.ct-series-j .ct-slice-pie,
.ct-series-j .ct-bar {
    fill: #6b0392
}

.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-point {
    stroke: #f05b4f
}

.ct-series-k .ct-area,
.ct-series-k .ct-slice-donut-solid,
.ct-series-k .ct-slice-pie,
.ct-series-k .ct-bar {
    fill: #f05b4f
}

.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-point {
    stroke: #dda458
}

.ct-series-l .ct-area,
.ct-series-l .ct-slice-donut-solid,
.ct-series-l .ct-slice-pie,
.ct-series-l .ct-bar {
    fill: #dda458
}

.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-point {
    stroke: #eacf7d
}

.ct-series-m .ct-area,
.ct-series-m .ct-slice-donut-solid,
.ct-series-m .ct-slice-pie,
.ct-series-m .ct-bar {
    fill: #eacf7d
}

.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-point {
    stroke: #86797d
}

.ct-series-n .ct-area,
.ct-series-n .ct-slice-donut-solid,
.ct-series-n .ct-slice-pie,
.ct-series-n .ct-bar {
    fill: #86797d
}

.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-point {
    stroke: #b2c326
}

.ct-series-o .ct-area,
.ct-series-o .ct-slice-donut-solid,
.ct-series-o .ct-slice-pie,
.ct-series-o .ct-bar {
    fill: #b2c326
}

.chartist-tooltip {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    min-width: 3.5em;
    background: #77797c
}

.chartist-tooltip:before {
    margin-left: -8px;
    border-width: 8px;
    border-top-color: #77797c
}

.ct-axis-title {
    fill: #96989c
}

.ct-label {
    color: rgba(45, 53, 65, 0.3)
}

.ct-grid {
    stroke: rgba(45, 53, 65, 0.05)
}

.ct-legend {
    position: relative;
    z-index: 5;
    text-align: right;
    padding: 0;
    stroke: rgba(45, 53, 65, 0.1)
}

.ct-legend li {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
    padding-left: 16px;
    margin-bottom: 3px;
    margin-right: 15px;
    list-style-type: none
}

.ct-legend li:before {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 5px;
    left: 0;
    content: '';
    border: 3px solid transparent
}

.ct-legend li.inactive:before {
    background: transparent
}

.ct-legend.ct-legend-inside {
    position: absolute;
    top: 0;
    right: 0
}

.ct-legend .ct-series-0:before {
    background-color: #36c2cf;
    border-color: #36c2cf
}

.ct-legend .ct-series-1:before {
    background-color: #5978ec;
    border-color: #5978ec
}

.ct-legend .ct-series-2:before {
    background-color: #f66d9b;
    border-color: #f66d9b
}

.ct-legend .ct-series-3:before {
    background-color: #ff9f24;
    border-color: #ff9f24
}

.ct-legend .ct-series-4:before {
    background-color: #ab8ce4;
    border-color: #ab8ce4
}

.ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905
}

.ct-legend .ct-series-6:before {
    background-color: #453d3f;
    border-color: #453d3f
}

.ct-legend .ct-series-7:before {
    background-color: #59922b;
    border-color: #59922b
}

.ct-legend .ct-series-8:before {
    background-color: #0544d3;
    border-color: #0544d3
}

.ct-legend .ct-series-9:before {
    background-color: #6b0392;
    border-color: #6b0392
}

.ct-legend .ct-series-10:before {
    background-color: #f05b4f;
    border-color: #f05b4f
}

.ct-legend .ct-series-11:before {
    background-color: #dda458;
    border-color: #dda458
}

.ct-legend .ct-series-12:before {
    background-color: #eacf7d;
    border-color: #eacf7d
}

.ct-legend .ct-series-13:before {
    background-color: #86797d;
    border-color: #86797d
}

.ct-legend .ct-series-14:before {
    background-color: #b2c326;
    border-color: #b2c326
}

#pie-chart .ct-label,
#donut-chart .ct-label {
    fill: rgba(45, 53, 65, 0.9)
}

.c3-chart-arc path {
    stroke: #fff
}

.c3-line {
    stroke-width: 1.5px
}

.morris-hover.morris-default-style {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0
}

.flot-chart {
    width: 100%;
    height: 320px
}

.panel-switch-btn {
    position: relative;
    right: 20px;
    z-index: 9
}

.panel-switch-btn label {
    font-weight: bold !important
}

.legendLabel {
    width: 85px !important;
    position: relative;
    left: 3px
}

#multiple_axis_chart .legendLabel {
    width: 160px !important
}

.sparkline {
    text-align: center
}

.dashboard-donut-chart {
    width: 280px;
    margin: 0 auto
}

.flot-tick-label.tickLabel {
    color: rgba(45, 53, 65, 0.5)
}

.z500px-color {
    color: #0099e5 !important
}

.about-me-color {
    color: #00405d !important
}

.airbnb-color {
    color: #fd5c63 !important
}

.alphabet-color {
    color: #ed1c24 !important
}

.amazon-color {
    color: #f90 !important
}

.american-express-color {
    color: #002663 !important
}

.aol-color {
    color: #ff0b00 !important
}

.behance-color {
    color: #1769ff !important
}

.bing-color {
    color: #ffb900 !important
}

.bitbucket-color {
    color: #205081 !important
}

.bitly-color {
    color: #ee6123 !important
}

.blogger-color {
    color: #f57d00 !important
}

.booking-com-color {
    color: #003580 !important
}

.buffer-color {
    color: #168eea !important
}

.code-school-color {
    color: #616f67 !important
}

.codecademy-color {
    color: #f65a5b !important
}

.creative-market-color {
    color: #8ba753 !important
}

.delicious-color {
    color: #39f !important
}

.deviantart-color {
    color: #05cc47 !important
}

.digg-color {
    color: #005be2 !important
}

.disqus-color {
    color: #2e9fff !important
}

.django-color {
    color: #092e20 !important
}

.dribbble-color {
    color: #ea4c89 !important
}

.dropbox-color {
    color: #007ee5 !important
}

.drupal-color {
    color: #0077c0 !important
}

.elance-color {
    color: #0d69af !important
}

.envato-color {
    color: #82b541 !important
}

.etsy-color {
    color: #d5641c !important
}

.facebook-color {
    color: #3b5998 !important
}

.feedly-color {
    color: #2bb24c !important
}

.flattr-color {
    color: #f67c1a !important
}

.flickr-color {
    color: #0063dc !important
}

.flipboard-color {
    color: #e12828 !important
}

.flixster-color {
    color: #2971b2 !important
}

.foursquare-color {
    color: #0732a2 !important
}

.github-color {
    color: #333 !important
}

.google-color {
    color: #4285f4 !important
}

.google-plus-color {
    color: #dc4e41 !important
}

.instagram-color {
    color: #3f729b !important
}

.kickstarter-color {
    color: #2bde73 !important
}

.kik-color {
    color: #82bc23 !important
}

.lastfm-color {
    color: #d51007 !important
}

.line-color {
    color: #00c300 !important
}

.linkedin-color {
    color: #0077b5 !important
}

.mail-ru-color {
    color: #168de2 !important
}

.mailchimp-color {
    color: #2c9ab7 !important
}

.myspace-color {
    color: #000 !important
}

.netflix-color {
    color: #e50914 !important
}

.ning-color {
    color: #75af42 !important
}

.path-color {
    color: #ee3423 !important
}

.patreon-color {
    color: #e6461a !important
}

.paypal-color {
    color: #003087 !important
}

.photobucket-color {
    color: #0ea0db !important
}

.pinterest-color {
    color: #bd081c !important
}

.product-hunt-color {
    color: #da552f !important
}

.quora-color {
    color: #a82400 !important
}

.rdio-color {
    color: #007dc3 !important
}

.reddit-color {
    color: #ff4500 !important
}

.rss-color {
    color: #f26522 !important
}

.salesforce-color {
    color: #1798c1 !important
}

.scribd-color {
    color: #1a7bba !important
}

.shopify-color {
    color: #96bf48 !important
}

.skype-color {
    color: #00aff0 !important
}

.slack-color {
    color: #6ecadc !important
}

.slideshare-color {
    color: #0077b5 !important
}

.soundcloud-color {
    color: #f80 !important
}

.spotify-color {
    color: #2ebd59 !important
}

.squarespace-color {
    color: #222 !important
}

.stackoverflow-color {
    color: #fe7a15 !important
}

.stripe-color {
    color: #00afe1 !important
}

.stumbleupon-color {
    color: #eb4924 !important
}

.telegram-color {
    color: #08c !important
}

.tumblr-color {
    color: #35465c !important
}

.twitch-tv-color {
    color: #6441a5 !important
}

.twitter-color {
    color: #55acee !important
}

.vimeo-color {
    color: #162221 !important
}

.vine-color {
    color: #00b488 !important
}

.wechat-color {
    color: #7bb32e !important
}

.whatsapp-color {
    color: #43d854 !important
}

.wordpress-color {
    color: #21759b !important
}

.yahoo-color {
    color: #410093 !important
}

.youtube-color {
    color: #cd201f !important
}

.z500px-bg {
    background-color: #0099e5 !important
}

.z500px-bg:hover,
.z500px-bg:focus {
    background-color: #08c !important
}

.about-me-bg {
    background-color: #00405d !important
}

.about-me-bg:hover,
.about-me-bg:focus {
    background-color: #002e44 !important
}

.airbnb-bg {
    background-color: #fd5c63 !important
}

.airbnb-bg:hover,
.airbnb-bg:focus {
    background-color: #fd434b !important
}

.alphabet-bg {
    background-color: #ed1c24 !important
}

.alphabet-bg:hover,
.alphabet-bg:focus {
    background-color: #de1219 !important
}

.amazon-bg {
    background-color: #f90 !important
}

.amazon-bg:hover,
.amazon-bg:focus {
    background-color: #e68a00 !important
}

.american-express-bg {
    background-color: #002663 !important
}

.american-express-bg:hover,
.american-express-bg:focus {
    background-color: #001c4a !important
}

.aol-bg {
    background-color: #ff0b00 !important
}

.aol-bg:hover,
.aol-bg:focus {
    background-color: #e60a00 !important
}

.behance-bg {
    background-color: #1769ff !important
}

.behance-bg:hover,
.behance-bg:focus {
    background-color: #0059fd !important
}

.bing-bg {
    background-color: #ffb900 !important
}

.bing-bg:hover,
.bing-bg:focus {
    background-color: #e6a700 !important
}

.bitbucket-bg {
    background-color: #205081 !important
}

.bitbucket-bg:hover,
.bitbucket-bg:focus {
    background-color: #1b436d !important
}

.bitly-bg {
    background-color: #ee6123 !important
}

.bitly-bg:hover,
.bitly-bg:focus {
    background-color: #e65312 !important
}

.blogger-bg {
    background-color: #f57d00 !important
}

.blogger-bg:hover,
.blogger-bg:focus {
    background-color: #dc7000 !important
}

.booking-com-bg {
    background-color: #003580 !important
}

.booking-com-bg:hover,
.booking-com-bg:focus {
    background-color: #002a67 !important
}

.buffer-bg {
    background-color: #168eea !important
}

.buffer-bg:hover,
.buffer-bg:focus {
    background-color: #1380d3 !important
}

.code-school-bg {
    background-color: #616f67 !important
}

.code-school-bg:hover,
.code-school-bg:focus {
    background-color: #55615a !important
}

.codecademy-bg {
    background-color: #f65a5b !important
}

.codecademy-bg:hover,
.codecademy-bg:focus {
    background-color: #f54243 !important
}

.creative-market-bg {
    background-color: #8ba753 !important
}

.creative-market-bg:hover,
.creative-market-bg:focus {
    background-color: #7d964b !important
}

.delicious-bg {
    background-color: #39f !important
}

.delicious-bg:hover,
.delicious-bg:focus {
    background-color: #1a8cff !important
}

.deviantart-bg {
    background-color: #05cc47 !important
}

.deviantart-bg:hover,
.deviantart-bg:focus {
    background-color: #04b33e !important
}

.digg-bg {
    background-color: #005be2 !important
}

.digg-bg:hover,
.digg-bg:focus {
    background-color: #0051c9 !important
}

.disqus-bg {
    background-color: #2e9fff !important
}

.disqus-bg:hover,
.disqus-bg:focus {
    background-color: #1593ff !important
}

.django-bg {
    background-color: #092e20 !important
}

.django-bg:hover,
.django-bg:focus {
    background-color: #051911 !important
}

.dribbble-bg {
    background-color: #ea4c89 !important
}

.dribbble-bg:hover,
.dribbble-bg:focus {
    background-color: #e7357a !important
}

.dropbox-bg {
    background-color: #007ee5 !important
}

.dropbox-bg:hover,
.dropbox-bg:focus {
    background-color: #0070cc !important
}

.drupal-bg {
    background-color: #0077c0 !important
}

.drupal-bg:hover,
.drupal-bg:focus {
    background-color: #0067a7 !important
}

.elance-bg {
    background-color: #0d69af !important
}

.elance-bg:hover,
.elance-bg:focus {
    background-color: #0b5b97 !important
}

.envato-bg {
    background-color: #82b541 !important
}

.envato-bg:hover,
.envato-bg:focus {
    background-color: #75a23a !important
}

.etsy-bg {
    background-color: #d5641c !important
}

.etsy-bg:hover,
.etsy-bg:focus {
    background-color: #be5919 !important
}

.facebook-bg {
    background-color: #3b5998 !important
}

.facebook-bg:hover,
.facebook-bg:focus {
    background-color: #344e86 !important
}

.feedly-bg {
    background-color: #2bb24c !important
}

.feedly-bg:hover,
.feedly-bg:focus {
    background-color: #269d43 !important
}

.flattr-bg {
    background-color: #f67c1a !important
}

.flattr-bg:hover,
.flattr-bg:focus {
    background-color: #ed6f09 !important
}

.flickr-bg {
    background-color: #0063dc !important
}

.flickr-bg:hover,
.flickr-bg:focus {
    background-color: #0058c3 !important
}

.flipboard-bg {
    background-color: #e12828 !important
}

.flipboard-bg:hover,
.flipboard-bg:focus {
    background-color: #d21d1d !important
}

.flixster-bg {
    background-color: #2971b2 !important
}

.flixster-bg:hover,
.flixster-bg:focus {
    background-color: #24649d !important
}

.foursquare-bg {
    background-color: #0732a2 !important
}

.foursquare-bg:hover,
.foursquare-bg:focus {
    background-color: #062a8a !important
}

.github-bg {
    background-color: #333 !important
}

.github-bg:hover,
.github-bg:focus {
    background-color: #262626 !important
}

.google-bg {
    background-color: #4285f4 !important
}

.google-bg:hover,
.google-bg:focus {
    background-color: #2a75f3 !important
}

.google-plus-bg {
    background-color: #dc4e41 !important
}

.google-plus-bg:hover,
.google-plus-bg:focus {
    background-color: #d83a2b !important
}

.instagram-bg {
    background-color: #3f729b !important
}

.instagram-bg:hover,
.instagram-bg:focus {
    background-color: #386589 !important
}

.kickstarter-bg {
    background-color: #2bde73 !important
}

.kickstarter-bg:hover,
.kickstarter-bg:focus {
    background-color: #20cf67 !important
}

.kik-bg {
    background-color: #82bc23 !important
}

.kik-bg:hover,
.kik-bg:focus {
    background-color: #73a71f !important
}

.lastfm-bg {
    background-color: #d51007 !important
}

.lastfm-bg:hover,
.lastfm-bg:focus {
    background-color: #bc0e06 !important
}

.line-bg {
    background-color: #00c300 !important
}

.line-bg:hover,
.line-bg:focus {
    background-color: #0a0 !important
}

.linkedin-bg {
    background-color: #0077b5 !important
}

.linkedin-bg:hover,
.linkedin-bg:focus {
    background-color: #00669c !important
}

.mail-ru-bg {
    background-color: #168de2 !important
}

.mail-ru-bg:hover,
.mail-ru-bg:focus {
    background-color: #147fcb !important
}

.mailchimp-bg {
    background-color: #2c9ab7 !important
}

.mailchimp-bg:hover,
.mailchimp-bg:focus {
    background-color: #2789a2 !important
}

.myspace-bg {
    background-color: #000 !important
}

.myspace-bg:hover,
.myspace-bg:focus {
    background-color: #000 !important
}

.netflix-bg {
    background-color: #e50914 !important
}

.netflix-bg:hover,
.netflix-bg:focus {
    background-color: #cc0812 !important
}

.ning-bg {
    background-color: #75af42 !important
}

.ning-bg:hover,
.ning-bg:focus {
    background-color: #699c3b !important
}

.path-bg {
    background-color: #ee3423 !important
}

.path-bg:hover,
.path-bg:focus {
    background-color: #e62412 !important
}

.patreon-bg {
    background-color: #e6461a !important
}

.patreon-bg:hover,
.patreon-bg:focus {
    background-color: #d03f17 !important
}

.paypal-bg {
    background-color: #003087 !important
}

.paypal-bg:hover,
.paypal-bg:focus {
    background-color: #00276e !important
}

.photobucket-bg {
    background-color: #0ea0db !important
}

.photobucket-bg:hover,
.photobucket-bg:focus {
    background-color: #0c8ec3 !important
}

.pinterest-bg {
    background-color: #bd081c !important
}

.pinterest-bg:hover,
.pinterest-bg:focus {
    background-color: #a50718 !important
}

.product-hunt-bg {
    background-color: #da552f !important
}

.product-hunt-bg:hover,
.product-hunt-bg:focus {
    background-color: #cb4924 !important
}

.quora-bg {
    background-color: #a82400 !important
}

.quora-bg:hover,
.quora-bg:focus {
    background-color: #8f1f00 !important
}

.rdio-bg {
    background-color: #007dc3 !important
}

.rdio-bg:hover,
.rdio-bg:focus {
    background-color: #006daa !important
}

.reddit-bg {
    background-color: #ff4500 !important
}

.reddit-bg:hover,
.reddit-bg:focus {
    background-color: #e63e00 !important
}

.rss-bg {
    background-color: #f26522 !important
}

.rss-bg:hover,
.rss-bg:focus {
    background-color: #ed560e !important
}

.salesforce-bg {
    background-color: #1798c1 !important
}

.salesforce-bg:hover,
.salesforce-bg:focus {
    background-color: #1486aa !important
}

.scribd-bg {
    background-color: #1a7bba !important
}

.scribd-bg:hover,
.scribd-bg:focus {
    background-color: #176ca4 !important
}

.shopify-bg {
    background-color: #96bf48 !important
}

.shopify-bg:hover,
.shopify-bg:focus {
    background-color: #89b03e !important
}

.skype-bg {
    background-color: #00aff0 !important
}

.skype-bg:hover,
.skype-bg:focus {
    background-color: #009cd7 !important
}

.slack-bg {
    background-color: #6ecadc !important
}

.slack-bg:hover,
.slack-bg:focus {
    background-color: #59c2d7 !important
}

.slideshare-bg {
    background-color: #0077b5 !important
}

.slideshare-bg:hover,
.slideshare-bg:focus {
    background-color: #00669c !important
}

.soundcloud-bg {
    background-color: #f80 !important
}

.soundcloud-bg:hover,
.soundcloud-bg:focus {
    background-color: #e67a00 !important
}

.spotify-bg {
    background-color: #2ebd59 !important
}

.spotify-bg:hover,
.spotify-bg:focus {
    background-color: #29a84f !important
}

.squarespace-bg {
    background-color: #222 !important
}

.squarespace-bg:hover,
.squarespace-bg:focus {
    background-color: #151515 !important
}

.stackoverflow-bg {
    background-color: #fe7a15 !important
}

.stackoverflow-bg:hover,
.stackoverflow-bg:focus {
    background-color: #f86c01 !important
}

.stripe-bg {
    background-color: #00afe1 !important
}

.stripe-bg:hover,
.stripe-bg:focus {
    background-color: #009bc8 !important
}

.stumbleupon-bg {
    background-color: #eb4924 !important
}

.stumbleupon-bg:hover,
.stumbleupon-bg:focus {
    background-color: #e13b15 !important
}

.telegram-bg {
    background-color: #08c !important
}

.telegram-bg:hover,
.telegram-bg:focus {
    background-color: #0077b3 !important
}

.tumblr-bg {
    background-color: #35465c !important
}

.tumblr-bg:hover,
.tumblr-bg:focus {
    background-color: #2c3a4c !important
}

.twitch-tv-bg {
    background-color: #6441a5 !important
}

.twitch-tv-bg:hover,
.twitch-tv-bg:focus {
    background-color: #593a93 !important
}

.twitter-bg {
    background-color: #55acee !important
}

.twitter-bg:hover,
.twitter-bg:focus {
    background-color: #3ea1ec !important
}

.vimeo-bg {
    background-color: #162221 !important
}

.vimeo-bg:hover,
.vimeo-bg:focus {
    background-color: #0c1312 !important
}

.vine-bg {
    background-color: #00b488 !important
}

.vine-bg:hover,
.vine-bg:focus {
    background-color: #009b75 !important
}

.wechat-bg {
    background-color: #7bb32e !important
}

.wechat-bg:hover,
.wechat-bg:focus {
    background-color: #6d9f29 !important
}

.whatsapp-bg {
    background-color: #43d854 !important
}

.whatsapp-bg:hover,
.whatsapp-bg:focus {
    background-color: #2ed441 !important
}

.wordpress-bg {
    background-color: #21759b !important
}

.wordpress-bg:hover,
.wordpress-bg:focus {
    background-color: #1d6586 !important
}

.yahoo-bg {
    background-color: #410093 !important
}

.yahoo-bg:hover,
.yahoo-bg:focus {
    background-color: #36007a !important
}

.youtube-bg {
    background-color: #cd201f !important
}

.youtube-bg:hover,
.youtube-bg:focus {
    background-color: #b71d1c !important
}

h6 {
    font-weight: 600
}

h3 {
    font-weight: 700
}

ul.list-justify>li {
    margin-bottom: 5px
}

ul.list-justify>li span,
ul.list-justify>li strong {
    float: right
}

ul.list-justify.large-number>li {
    margin-bottom: 15px
}

ul.list-justify.large-number span {
    font-size: 32px;
    line-height: 1
}

.text-primary {
    color: #5c8ed4
}

.text-info {
    color: #3C89DA
}

.text-success {
    color: #5CB65F
}

.text-warning {
    color: #FFA117
}

.text-danger {
    color: #E15858
}

.text-muted {
    color: #9A9A9A
}

.text-larger {
    font-size: 1.2em
}

.text-large {
    font-size: 1.53em
}

small {
    font-size: 90%
}

.bg-info {
    background: #3C89DA;
    color: rgba(255, 255, 255, 0.7)
}

.bg-red {
    background: #E15858;
    color: rgba(255, 255, 255, 0.7)
}

.bg-purple {
    background: #a5a;
    color: rgba(255, 255, 255, 0.7)
}

.bg-orange {
    background: #FFA117;
    color: rgba(255, 255, 255, 0.7)
}

.bg-green {
    background: #5CB65F;
    color: rgba(255, 255, 255, 0.7)
}

.bg-blue {
    background: #36c2cf;
    color: rgba(255, 255, 255, 0.7)
}

.bg-blush {
    background: #e25985;
    color: rgba(255, 255, 255, 0.7)
}

.bg-pink {
    background: #f66d9b;
    color: rgba(255, 255, 255, 0.7)
}

.bg-indigo {
    background: #ab8ce4;
    color: rgba(255, 255, 255, 0.7)
}

.bg-azura {
    background: #007FFF;
    color: rgba(255, 255, 255, 0.7)
}

.bg-white {
    background: #fff;
    color: rgba(255, 255, 255, 0.7)
}

.bg-dark {
    background: #2D3541;
    color: rgba(255, 255, 255, 0.7)
}

.bg-cyan {
    background: #36c2cf;
    color: rgba(255, 255, 255, 0.7)
}

.lbg-info {
    background: rgba(60, 137, 218, 0.2);
    color: #3C89DA
}

.lbg-red {
    background: rgba(225, 88, 88, 0.2);
    color: #E15858
}

.lbg-purple {
    background: rgba(170, 85, 170, 0.2);
    color: #a5a
}

.lbg-orange {
    background: rgba(255, 161, 23, 0.2);
    color: #FFA117
}

.lbg-green {
    background: rgba(92, 182, 95, 0.2);
    color: #5CB65F
}

.lbg-blue {
    background: rgba(57, 91, 182, 0.2);
    color: #36c2cf
}

.lbg-blush {
    background: rgba(226, 89, 133, 0.2);
    color: #e25985
}

.lbg-pink {
    background: rgba(246, 109, 155, 0.2);
    color: #f66d9b
}

.lbg-indigo {
    background: rgba(171, 140, 228, 0.2);
    color: #ab8ce4
}

.lbg-azura {
    background: rgba(0, 127, 255, 0.2);
    color: #007FFF
}

.lbg-dark {
    background: rgba(45, 53, 65, 0.2);
    color: #2D3541
}

.lbg-cyan {
    background: rgba(54, 194, 207, 0.2);
    color: #36c2cf
}

.text-info {
    color: #3C89DA
}

.text-red {
    color: #E15858
}

.text-purple {
    color: #a5a
}

.text-orange {
    color: #FFA117
}

.text-green {
    color: #5CB65F
}

.text-blue {
    color: #36c2cf
}

.text-blush {
    color: #e25985
}

.text-pink {
    color: #f66d9b
}

.text-indigo {
    color: #ab8ce4
}

.text-azura {
    color: #007FFF
}

.text-white {
    color: #fff
}

.text-dark {
    color: #2D3541
}

.m-l--125 {
    margin-left: -125px
}

.m-t--125 {
    margin-top: -125px
}

.m-r--125 {
    margin-right: -125px
}

.m-b--125 {
    margin-bottom: -125px
}

.m-l--120 {
    margin-left: -120px
}

.m-t--120 {
    margin-top: -120px
}

.m-r--120 {
    margin-right: -120px
}

.m-b--120 {
    margin-bottom: -120px
}

.m-l--115 {
    margin-left: -115px
}

.m-t--115 {
    margin-top: -115px
}

.m-r--115 {
    margin-right: -115px
}

.m-b--115 {
    margin-bottom: -115px
}

.m-l--110 {
    margin-left: -110px
}

.m-t--110 {
    margin-top: -110px
}

.m-r--110 {
    margin-right: -110px
}

.m-b--110 {
    margin-bottom: -110px
}

.m-l--105 {
    margin-left: -105px
}

.m-t--105 {
    margin-top: -105px
}

.m-r--105 {
    margin-right: -105px
}

.m-b--105 {
    margin-bottom: -105px
}

.m-l--100 {
    margin-left: -100px
}

.m-t--100 {
    margin-top: -100px
}

.m-r--100 {
    margin-right: -100px
}

.m-b--100 {
    margin-bottom: -100px
}

.m-l--95 {
    margin-left: -95px
}

.m-t--95 {
    margin-top: -95px
}

.m-r--95 {
    margin-right: -95px
}

.m-b--95 {
    margin-bottom: -95px
}

.m-l--90 {
    margin-left: -90px
}

.m-t--90 {
    margin-top: -90px
}

.m-r--90 {
    margin-right: -90px
}

.m-b--90 {
    margin-bottom: -90px
}

.m-l--85 {
    margin-left: -85px
}

.m-t--85 {
    margin-top: -85px
}

.m-r--85 {
    margin-right: -85px
}

.m-b--85 {
    margin-bottom: -85px
}

.m-l--80 {
    margin-left: -80px
}

.m-t--80 {
    margin-top: -80px
}

.m-r--80 {
    margin-right: -80px
}

.m-b--80 {
    margin-bottom: -80px
}

.m-l--75 {
    margin-left: -75px
}

.m-t--75 {
    margin-top: -75px
}

.m-r--75 {
    margin-right: -75px
}

.m-b--75 {
    margin-bottom: -75px
}

.m-l--70 {
    margin-left: -70px
}

.m-t--70 {
    margin-top: -70px
}

.m-r--70 {
    margin-right: -70px
}

.m-b--70 {
    margin-bottom: -70px
}

.m-l--65 {
    margin-left: -65px
}

.m-t--65 {
    margin-top: -65px
}

.m-r--65 {
    margin-right: -65px
}

.m-b--65 {
    margin-bottom: -65px
}

.m-l--60 {
    margin-left: -60px
}

.m-t--60 {
    margin-top: -60px
}

.m-r--60 {
    margin-right: -60px
}

.m-b--60 {
    margin-bottom: -60px
}

.m-l--55 {
    margin-left: -55px
}

.m-t--55 {
    margin-top: -55px
}

.m-r--55 {
    margin-right: -55px
}

.m-b--55 {
    margin-bottom: -55px
}

.m-l--50 {
    margin-left: -50px
}

.m-t--50 {
    margin-top: -50px
}

.m-r--50 {
    margin-right: -50px
}

.m-b--50 {
    margin-bottom: -50px
}

.m-l--45 {
    margin-left: -45px
}

.m-t--45 {
    margin-top: -45px
}

.m-r--45 {
    margin-right: -45px
}

.m-b--45 {
    margin-bottom: -45px
}

.m-l--40 {
    margin-left: -40px
}

.m-t--40 {
    margin-top: -40px
}

.m-r--40 {
    margin-right: -40px
}

.m-b--40 {
    margin-bottom: -40px
}

.m-l--35 {
    margin-left: -35px
}

.m-t--35 {
    margin-top: -35px
}

.m-r--35 {
    margin-right: -35px
}

.m-b--35 {
    margin-bottom: -35px
}

.m-l--30 {
    margin-left: -30px
}

.m-t--30 {
    margin-top: -30px
}

.m-r--30 {
    margin-right: -30px
}

.m-b--30 {
    margin-bottom: -30px
}

.m-l--25 {
    margin-left: -25px
}

.m-t--25 {
    margin-top: -25px
}

.m-r--25 {
    margin-right: -25px
}

.m-b--25 {
    margin-bottom: -25px
}

.m-l--20 {
    margin-left: -20px
}

.m-t--20 {
    margin-top: -20px
}

.m-r--20 {
    margin-right: -20px
}

.m-b--20 {
    margin-bottom: -20px
}

.m-l--15 {
    margin-left: -15px
}

.m-t--15 {
    margin-top: -15px
}

.m-r--15 {
    margin-right: -15px
}

.m-b--15 {
    margin-bottom: -15px
}

.m-l--10 {
    margin-left: -10px
}

.m-t--10 {
    margin-top: -10px
}

.m-r--10 {
    margin-right: -10px
}

.m-b--10 {
    margin-bottom: -10px
}

.m-l--5 {
    margin-left: -5px
}

.m-t--5 {
    margin-top: -5px
}

.m-r--5 {
    margin-right: -5px
}

.m-b--5 {
    margin-bottom: -5px
}

.m-l-0,
.timeline .timeline-content p:last-child {
    margin-left: 0px
}

.m-t-0,
#left-sidebar {
    margin-top: 0px
}

.m-r-0,
.panel-group .panel .panel-title>a i.right,
.chatapp_body .chat-history .message_data li.left .message:after {
    margin-right: 0px
}

.m-b-0,
.card .header,
ul.choose-skin,
ul.choose-skin li:last-child,
.auth-main .lead,
.block-header h2,
.block-header h1,
.block-header .breadcrumb,
#toast-container .toast,
.top_counter .content .number,
.feeds_widget,
.table-calendar,
.mail-inbox .mail-right .mail-action .fancy-checkbox label,
.mail-inbox .mail-right .mail-action .pagination-email p,
.mail-inbox .mail-right .mail-list ul li .md-left .fancy-checkbox,
.mail-inbox .mail-right .mail-list ul li .md-right p,
.pricing,
.pricing h3 {
    margin-bottom: 0px
}

.m-l-5,
.badge,
.btn-toolbar>.btn-group,
.wizard .actions a {
    margin-left: 5px
}

.m-t-5,
.card .header h2 small,
.auth-main .lead,
.block-header .inlineblock,
.block-header .metismenu>li i,
.metismenu>li .block-header i {
    margin-top: 5px
}

.m-r-5,
.nav.nav-tabs2>li>a,
.nav.nav-tabs3>li>a,
.badge,
.panel-group .panel .panel-title>a i {
    margin-right: 5px
}

.m-b-5,
.metismenu li.header,
ul.choose-skin li,
.auth-main .btn-signin-social,
.accordion>div {
    margin-bottom: 5px
}

.m-l-10,
.navbar-fixed-top .navbar-brand span,
.accordion2 .accordion-thumb span,
.setting-list li>span,
.mail-inbox .mail-right .mail-list ul li .md-right p {
    margin-left: 10px
}

.m-t-10,
.page-loader-wrapper p,
.chatapp_body .chat-history .message_data li {
    margin-top: 10px
}

.m-r-10,
.metismenu>li i,
.top_counter .icon,
.right_chat .media .media-object {
    margin-right: 10px
}

.m-b-10,
.wizard.step_icon .steps {
    margin-bottom: 10px
}

.m-l-15 {
    margin-left: 15px
}

.m-t-15,
.tab-content,
.block-header {
    margin-top: 15px
}

.m-r-15,
.navbar-fixed-top .navbar-brand,
.navbar-nav,
.feeds_widget li .feeds-left,
.w_knob .c_know,
.team-info li .first {
    margin-right: 15px
}

.m-b-15,
.navbar-nav .dropdown-menu li.header,
.block-header,
.w_social3 .card .fa {
    margin-bottom: 15px
}

.m-l-20,
.media.mright .media-right,
.chat-widget li.left .chat-info,
.invoice1 .info {
    margin-left: 20px
}

.m-t-20,
.metismenu li.header,
.auth-main .form-auth-small .btn,
.auth-main .form-auth-small .bottom,
.pricing .price {
    margin-top: 20px
}

.m-r-20,
.navbar-fixed-top #navbar-search,
.block-header .breadcrumb,
.media.mleft .media-left,
.bs-example .media .media-left,
.chat-widget li.right .chat-info {
    margin-right: 20px
}

.m-b-20,
.media,
.profilepage_2 .profile-header .profile-image,
.news .card-columns .card {
    margin-bottom: 20px
}

.m-l-25 {
    margin-left: 25px
}

.m-t-25 {
    margin-top: 25px
}

.m-r-25 {
    margin-right: 25px
}

.m-b-25 {
    margin-bottom: 25px
}

.m-l-30 {
    margin-left: 30px
}

.m-t-30,
.pricing .plan-btn {
    margin-top: 30px
}

.m-r-30 {
    margin-right: 30px
}

.m-b-30,
.auth-main .auth_brand,
.chat-widget li,
.pricing .price {
    margin-bottom: 30px
}

.m-l-35 {
    margin-left: 35px
}

.m-t-35 {
    margin-top: 35px
}

.m-r-35 {
    margin-right: 35px
}

.m-b-35 {
    margin-bottom: 35px
}

.m-l-40 {
    margin-left: 40px
}

.m-t-40 {
    margin-top: 40px
}

.m-r-40 {
    margin-right: 40px
}

.m-b-40,
.chatapp_body .chat-history .message_data li {
    margin-bottom: 40px
}

.m-l-45 {
    margin-left: 45px
}

.m-t-45 {
    margin-top: 45px
}

.m-r-45 {
    margin-right: 45px
}

.m-b-45 {
    margin-bottom: 45px
}

.m-l-50,
.chatapp_body .chat-history .message_data li.left .message {
    margin-left: 50px
}

.m-t-50 {
    margin-top: 50px
}

.m-r-50,
.chatapp_body .chat-history .message_data li.right .message {
    margin-right: 50px
}

.m-b-50 {
    margin-bottom: 50px
}

.m-l-55 {
    margin-left: 55px
}

.m-t-55 {
    margin-top: 55px
}

.m-r-55 {
    margin-right: 55px
}

.m-b-55 {
    margin-bottom: 55px
}

.m-l-60 {
    margin-left: 60px
}

.m-t-60 {
    margin-top: 60px
}

.m-r-60 {
    margin-right: 60px
}

.m-b-60 {
    margin-bottom: 60px
}

.m-l-65 {
    margin-left: 65px
}

.m-t-65 {
    margin-top: 65px
}

.m-r-65 {
    margin-right: 65px
}

.m-b-65 {
    margin-bottom: 65px
}

.m-l-70 {
    margin-left: 70px
}

.m-t-70 {
    margin-top: 70px
}

.m-r-70 {
    margin-right: 70px
}

.m-b-70 {
    margin-bottom: 70px
}

.m-l-75 {
    margin-left: 75px
}

.m-t-75 {
    margin-top: 75px
}

.m-r-75 {
    margin-right: 75px
}

.m-b-75 {
    margin-bottom: 75px
}

.m-l-80 {
    margin-left: 80px
}

.m-t-80 {
    margin-top: 80px
}

.m-r-80 {
    margin-right: 80px
}

.m-b-80 {
    margin-bottom: 80px
}

.m-l-85 {
    margin-left: 85px
}

.m-t-85 {
    margin-top: 85px
}

.m-r-85 {
    margin-right: 85px
}

.m-b-85 {
    margin-bottom: 85px
}

.m-l-90 {
    margin-left: 90px
}

.m-t-90 {
    margin-top: 90px
}

.m-r-90 {
    margin-right: 90px
}

.m-b-90 {
    margin-bottom: 90px
}

.m-l-95 {
    margin-left: 95px
}

.m-t-95 {
    margin-top: 95px
}

.m-r-95 {
    margin-right: 95px
}

.m-b-95 {
    margin-bottom: 95px
}

.m-l-100 {
    margin-left: 100px
}

.m-t-100 {
    margin-top: 100px
}

.m-r-100 {
    margin-right: 100px
}

.m-b-100 {
    margin-bottom: 100px
}

.m-l-105 {
    margin-left: 105px
}

.m-t-105 {
    margin-top: 105px
}

.m-r-105 {
    margin-right: 105px
}

.m-b-105 {
    margin-bottom: 105px
}

.m-l-110 {
    margin-left: 110px
}

.m-t-110 {
    margin-top: 110px
}

.m-r-110 {
    margin-right: 110px
}

.m-b-110 {
    margin-bottom: 110px
}

.m-l-115 {
    margin-left: 115px
}

.m-t-115 {
    margin-top: 115px
}

.m-r-115 {
    margin-right: 115px
}

.m-b-115 {
    margin-bottom: 115px
}

.m-l-120 {
    margin-left: 120px
}

.m-t-120 {
    margin-top: 120px
}

.m-r-120 {
    margin-right: 120px
}

.m-b-120 {
    margin-bottom: 120px
}

.m-l-125 {
    margin-left: 125px
}

.m-t-125 {
    margin-top: 125px
}

.m-r-125 {
    margin-right: 125px
}

.m-b-125 {
    margin-bottom: 125px
}

.margin-0,
.card .header .header-dropdown,
.card .header h2,
.navbar-nav,
#left-sidebar .sidebar-nav ul,
.accordion2,
.accordion2 .accordion-thumb,
.accordion2 .accordion-panel,
.switch,
.media .media-body .media-heading,
.wizard .content,
.wizard .content .body label.error,
.project_report h6,
.project_report .team-info,
.todo_list,
.todo_list .fancy-checkbox,
.feeds_widget li .feeds-body .title,
.chatapp_body .chat-history .message_data,
.timeline,
.timeline .period .timeline-title,
.fc .fc-toolbar,
.fc .fc-toolbar h2 {
    margin: 0 !important
}

.p-l-0,
.pricing,
.invoice .nav-tabs {
    padding-left: 0px
}

.p-t-0 {
    padding-top: 0px
}

.p-r-0,
.invoice .nav-tabs {
    padding-right: 0px
}

.p-b-0,
.timeline .timeline-item:last-child {
    padding-bottom: 0px
}

.p-l-5 {
    padding-left: 5px
}

.p-t-5,
.fc .fc-toolbar h2 {
    padding-top: 5px
}

.p-r-5,
.wizard.vertical>.steps {
    padding-right: 5px
}

.p-b-5,
.metismenu>li,
.metismenu li.header {
    padding-bottom: 5px
}

.p-l-10,
.metismenu li.header {
    padding-left: 10px
}

.p-t-10 {
    padding-top: 10px
}

.p-r-10 {
    padding-right: 10px
}

.p-b-10,
.accordion2 .accordion-panel,
.fc .fc-toolbar {
    padding-bottom: 10px
}

.p-l-15 {
    padding-left: 15px
}

.p-t-15 {
    padding-top: 15px
}

.p-r-15,
.navbar-fixed-top .navbar-brand {
    padding-right: 15px
}

.p-b-15 {
    padding-bottom: 15px
}

.p-l-20,
.navbar-fixed-top #navbar-search .form-control {
    padding-left: 20px
}

.p-t-20 {
    padding-top: 20px
}

.p-r-20 {
    padding-right: 20px
}

.p-b-20 {
    padding-bottom: 20px
}

.p-l-25 {
    padding-left: 25px
}

.p-t-25 {
    padding-top: 25px
}

.p-r-25 {
    padding-right: 25px
}

.p-b-25 {
    padding-bottom: 25px
}

.p-l-30 {
    padding-left: 30px
}

.p-t-30 {
    padding-top: 30px
}

.p-r-30 {
    padding-right: 30px
}

.p-b-30 {
    padding-bottom: 30px
}

.p-l-35 {
    padding-left: 35px
}

.p-t-35 {
    padding-top: 35px
}

.p-r-35 {
    padding-right: 35px
}

.p-b-35 {
    padding-bottom: 35px
}

.p-l-40,
.timeline .timeline-item {
    padding-left: 40px
}

.p-t-40 {
    padding-top: 40px
}

.p-r-40 {
    padding-right: 40px
}

.p-b-40,
.timeline .timeline-content {
    padding-bottom: 40px
}

.p-l-45 {
    padding-left: 45px
}

.p-t-45 {
    padding-top: 45px
}

.p-r-45 {
    padding-right: 45px
}

.p-b-45 {
    padding-bottom: 45px
}

.p-l-50 {
    padding-left: 50px
}

.p-t-50 {
    padding-top: 50px
}

.p-r-50 {
    padding-right: 50px
}

.p-b-50 {
    padding-bottom: 50px
}

.p-l-55 {
    padding-left: 55px
}

.p-t-55 {
    padding-top: 55px
}

.p-r-55 {
    padding-right: 55px
}

.p-b-55 {
    padding-bottom: 55px
}

.p-l-60 {
    padding-left: 60px
}

.p-t-60 {
    padding-top: 60px
}

.p-r-60 {
    padding-right: 60px
}

.p-b-60 {
    padding-bottom: 60px
}

.p-l-65 {
    padding-left: 65px
}

.p-t-65 {
    padding-top: 65px
}

.p-r-65 {
    padding-right: 65px
}

.p-b-65 {
    padding-bottom: 65px
}

.p-l-70 {
    padding-left: 70px
}

.p-t-70 {
    padding-top: 70px
}

.p-r-70 {
    padding-right: 70px
}

.p-b-70 {
    padding-bottom: 70px
}

.p-l-75 {
    padding-left: 75px
}

.p-t-75 {
    padding-top: 75px
}

.p-r-75 {
    padding-right: 75px
}

.p-b-75 {
    padding-bottom: 75px
}

.p-l-80 {
    padding-left: 80px
}

.p-t-80 {
    padding-top: 80px
}

.p-r-80 {
    padding-right: 80px
}

.p-b-80 {
    padding-bottom: 80px
}

.p-l-85 {
    padding-left: 85px
}

.p-t-85 {
    padding-top: 85px
}

.p-r-85 {
    padding-right: 85px
}

.p-b-85 {
    padding-bottom: 85px
}

.p-l-90 {
    padding-left: 90px
}

.p-t-90 {
    padding-top: 90px
}

.p-r-90 {
    padding-right: 90px
}

.p-b-90 {
    padding-bottom: 90px
}

.p-l-95 {
    padding-left: 95px
}

.p-t-95 {
    padding-top: 95px
}

.p-r-95 {
    padding-right: 95px
}

.p-b-95 {
    padding-bottom: 95px
}

.p-l-100 {
    padding-left: 100px
}

.p-t-100 {
    padding-top: 100px
}

.p-r-100 {
    padding-right: 100px
}

.p-b-100 {
    padding-bottom: 100px
}

.p-l-105 {
    padding-left: 105px
}

.p-t-105 {
    padding-top: 105px
}

.p-r-105 {
    padding-right: 105px
}

.p-b-105 {
    padding-bottom: 105px
}

.p-l-110 {
    padding-left: 110px
}

.p-t-110 {
    padding-top: 110px
}

.p-r-110 {
    padding-right: 110px
}

.p-b-110 {
    padding-bottom: 110px
}

.p-l-115 {
    padding-left: 115px
}

.p-t-115 {
    padding-top: 115px
}

.p-r-115 {
    padding-right: 115px
}

.p-b-115 {
    padding-bottom: 115px
}

.p-l-120 {
    padding-left: 120px
}

.p-t-120 {
    padding-top: 120px
}

.p-r-120 {
    padding-right: 120px
}

.p-b-120 {
    padding-bottom: 120px
}

.p-l-125 {
    padding-left: 125px
}

.p-t-125 {
    padding-top: 125px
}

.p-r-125 {
    padding-right: 125px
}

.p-b-125 {
    padding-bottom: 125px
}

.padding-0,
.card .header .header-dropdown,
.navbar-fixed-top #navbar-search,
.navbar-fixed-top #navbar-search .btn,
#left-sidebar .sidebar-nav ul,
.block-header .breadcrumb,
.accordion .card-header,
.accordion2,
.chat-widget,
.right_chat .media .status,
.chatapp_body .chat-history .message_data,
.timeline,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr th.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-widget-header,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-widget-header {
    padding: 0 !important
}

.font-6 {
    font-size: 6px
}

.font-7 {
    font-size: 7px
}

.font-8,
.multiselect-custom+.btn-group ul.multiselect-container>li.active>a label.radio:before,
.invoice_list .badge {
    font-size: 8px
}

.font-9 {
    font-size: 9px
}

.font-10,
.progress .progress-bar {
    font-size: 10px
}

.font-11 {
    font-size: 11px
}

.font-12,
.metismenu li.header,
.navbar-fixed-top #navbar-search .form-control,
.navbar-fixed-top #navbar-search i,
.navbar-nav ul.email.right_chat .media .name small,
.multiselect-custom+.btn-group ul.multiselect-container>li.active>a label.checkbox:before,
.ct-label,
.wizard .content .body label.error,
.feeds_widget li .feeds-body>small,
.w_summary .s_detail span,
.invoice_list .table.table-custom thead th,
.avtar-pic span,
.chatapp_body .chat-history .message_data li .data_time,
.mail-inbox .mail-right .mail-list ul li .md-right span.time,
.timeline .timeline-info,
.fc .fc-view-container .fc-event,
.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-allday .fc-agenda-axis,
.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-allday .fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-axis,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-gutter,
.pricing h3 small,
.n_category .sub_n_category {
    font-size: 12px
}

.font-13,
.card .header h2 small,
.right_chat .media .message {
    font-size: 13px
}

.font-14,
.block-header .breadcrumb {
    font-size: 14px
}

.font-15,
.metismenu a,
.nav.nav-tabs3>li>a,
.multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox,
.accordion .card-header .btn,
.panel-group .panel .panel-title,
.wizard .content .body p,
.right_chat .media .name,
.feeds_widget li .feeds-body .title,
.chatapp_body .chat-history .message_data li .message,
.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-day-number,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-day-number,
.pricing h3 span {
    font-size: 15px
}

.font-16,
.auth-main .btn-signin-social i,
.accordion2 .accordion-thumb,
.modal-title,
.media .media-body .media-heading,
.fc .fc-toolbar h2,
.news .card-title {
    font-size: 16px
}

.font-17,
.navbar-fixed-top .navbar-brand span,
.fancy-checkbox input[type="checkbox"]:checked+span:before {
    font-size: 17px
}

.font-18,
.card .header .header-dropdown li a.dropdown-toggle:after,
.card .header h2,
.separator-linethrough span,
.panel-group .panel .panel-title>a i,
.feeds_widget li .feeds-left {
    font-size: 18px
}

.font-19 {
    font-size: 19px
}

.font-20,
.auth-main .lead,
.top_counter .icon i,
.timeline .timeline-content .timeline-title {
    font-size: 20px
}

.font-21 {
    font-size: 21px
}

.font-22,
.block-header h2,
.block-header h1 {
    font-size: 22px
}

.font-23 {
    font-size: 23px
}

.font-24 {
    font-size: 24px
}

.font-25 {
    font-size: 25px
}

.font-26 {
    font-size: 26px
}

.font-27 {
    font-size: 27px
}

.font-28 {
    font-size: 28px
}

.font-29 {
    font-size: 29px
}

.font-30,
.w_card3 i {
    font-size: 30px
}

.font-31 {
    font-size: 31px
}

.font-32 {
    font-size: 32px
}

.font-33 {
    font-size: 33px
}

.font-34 {
    font-size: 34px
}

.font-35,
.pricing h3 {
    font-size: 35px
}

.font-36 {
    font-size: 36px
}

.font-37 {
    font-size: 37px
}

.font-38 {
    font-size: 38px
}

.font-39 {
    font-size: 39px
}

.font-40 {
    font-size: 40px
}

.font-41 {
    font-size: 41px
}

.font-42 {
    font-size: 42px
}

.font-43 {
    font-size: 43px
}

.font-44 {
    font-size: 44px
}

.font-45 {
    font-size: 45px
}

.font-46 {
    font-size: 46px
}

.font-47 {
    font-size: 47px
}

.font-48 {
    font-size: 48px
}

.font-49 {
    font-size: 49px
}

.font-50 {
    font-size: 50px
}

.align-left,
.accordion .card-header .btn,
.chat-widget li.left .chat-info,
.chatapp_body .chat-history .message_data li.left .message {
    text-align: left
}

.align-center,
.fancy-checkbox input[type="checkbox"]:checked+span:before,
.multiselect-custom+.btn-group ul.multiselect-container>li.active>a label.checkbox:before,
.multiselect-custom+.btn-group ul.multiselect-container>li.active>a label.radio:before,
.auth-main .form-auth-small .bottom,
.metric-inline,
.separator-linethrough,
.page-loader-wrapper,
.top_counter .icon,
.stamp,
.table-calendar,
.profilepage_2 .profile-header,
.pricing,
.w_social3 .card,
.member-card {
    text-align: center
}

.align-right,
.media.mright .media-body,
.wizard .actions,
.chat-widget li.right,
.chat-widget li.right .chat-info,
.chatapp_body .chat-history .message_data li.right .message {
    text-align: right
}

.align-justify {
    text-align: justify
}

.border-right,
.border-top,
.border-left,
.border-bottom {
    border-color: #ced4da !important
}

.w30 {
    width: 30px;
    height: 30px;
    line-height: 30px
}

.w35 {
    width: 35px;
    height: 35px
}

.w40 {
    width: 40px
}

.w60 {
    width: 60px
}

.w80 {
    width: 80px
}

.w100 {
    width: 100px
}

.title_font {
    font-family: "Saira", sans-serif
}

.inlineblock,
.metismenu>li i {
    display: inline-block
}

.displayblock {
    display: block
}

.displaynone {
    display: none
}

.overflowhidden,
.member-card {
    overflow: hidden
}

.z-index {
    z-index: 9999
}

hr {
    border-top: 1px solid rgba(45, 53, 65, 0.1)
}

.p-10 {
    padding: 10px !important
}

.p-15 {
    padding: 15px !important
}

.p-20 {
    padding: 20px !important
}

.p-25 {
    padding: 25px !important
}

.font300 {
    font-weight: 300
}

.font600 {
    font-weight: 600
}

.font700 {
    font-weight: 700
}

.media .media-body {
    font-size: 15px
}

.media .media-body .media-heading {
    font-weight: 700
}

.wizard .content {
    min-height: 245px;
    overflow-y: auto;
    border-radius: 0;
    background-color: transparent;
    border: 1px solid #ced4da
}

.wizard .content .body {
    padding: 15px
}

.wizard .content .body label.error {
    color: #E15858
}

.wizard .content .body input[type="checkbox"] {
    display: none
}

.wizard .content .body input {
    border-color: #ced4da
}

.wizard .actions {
    position: relative;
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    width: 100%;
    margin-top: 10px !important
}

.wizard .actions ul {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline
}

.wizard .actions ul>li {
    float: left;
    margin: 0 !important
}

.wizard .actions a {
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    padding: 0.5em 2em;
    color: #fff;
    text-decoration: none
}

.wizard .actions a:hover,
.wizard .actions a:active {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    padding: 0.5em 2em;
    color: #fff;
    text-decoration: none
}

.wizard .actions .disabled a {
    background: #e2e4e7;
    color: #bbbec2
}

.wizard .actions .disabled a:hover,
.wizard .actions .disabled a:active {
    background: #e2e4e7;
    color: #bbbec2
}

.wizard .steps {
    position: relative;
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    width: 100%;
    margin-bottom: 2px
}

.wizard .steps a {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    width: auto;
    margin: 0 2px 0 0;
    padding: 10px;
    text-decoration: none;
    border-radius: 0
}

.wizard .steps a:hover,
.wizard .steps a:active {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    width: auto;
    margin: 0 2px 0 0;
    padding: 10px;
    text-decoration: none;
    border-radius: 0
}

.wizard .steps .number {
    font-size: 15px
}

.wizard .steps .disabled a {
    background: #ced4da;
    color: #96989c;
    cursor: default
}

.wizard .steps .disabled a:hover,
.wizard .steps .disabled a:active {
    background: #ced4da;
    color: #96989c;
    cursor: default
}

.wizard .steps .current a {
    color: #fff;
    cursor: default
}

.wizard .steps .current a:hover,
.wizard .steps .current a:active {
    color: #fff;
    cursor: default
}

.wizard .steps .done a {
    color: #fff;
    opacity: 0.7
}

.wizard .steps .done a:hover,
.wizard .steps .done a:active {
    color: #fff;
    opacity: 0.7
}

.wizard .steps>ul>li {
    width: 25%;
    float: left
}

.wizard .steps>ul>li:last-child a {
    margin: 0
}

.wizard.vertical>.steps {
    float: left;
    width: 30%
}

.wizard.vertical>.steps>ul>li {
    float: none;
    width: 100%
}

.wizard.vertical>.steps>ul>li a {
    margin: 0 0 2px 0
}

.wizard.vertical>.content {
    float: left;
    width: 70%;
    margin: 0
}

.wizard.vertical>.actions {
    float: right;
    width: 100%;
    margin: 0
}

.wizard.step_icon .content {
    min-height: 200px;
    background-color: #ced4da;
    border: 1px solid #ced4da
}

.wizard.step_icon .steps>ul>li {
    width: auto;
    text-align: center
}

.wizard.step_icon .steps>ul>li a {
    width: 40px;
    height: 40px;
    border-radius: .1875rem
}

.wizard.step_icon .steps .number {
    display: none
}

.wizard_validation .wizard .steps>ul>li {
    width: auto !important
}

@media screen and (max-width: 767px) {
    .wizard>.steps>ul>li {
        width: 100%;
        margin-bottom: 2px
    }

    .wizard>.steps a {
        margin: 0
    }

    .wizard.wizard.vertical>.content,
    .wizard.wizard.vertical>.steps {
        width: 100%;
        padding: 0
    }

    .wizard_validation .wizard .steps>ul>li {
        width: 100% !important
    }
}

.table .thead-primary th {
    color: #36c2cf;
    border-color: #36c2cf
}

.table .thead-success th {
    color: #5CB65F;
    border-color: #5CB65F
}

.table .thead-danger th {
    color: #E15858;
    border-color: #E15858
}

.table .thead-info th {
    color: #3C89DA;
    border-color: #3C89DA
}

.table .thead-purple th {
    color: #a5a;
    border-color: #a5a
}

.table thead th {
    font-weight: 400;
    border-width: 1px
}

.table tr td,
.table tr th {
    vertical-align: middle;
    white-space: nowrap;
    border-color: #ced4da
}

.table.table-custom {
    border-collapse: separate !important
}

.table.table-custom.spacing5 {
    border-spacing: 0 5px !important
}

.table.table-custom.spacing8 {
    border-spacing: 0 8px !important
}

.table.table-custom thead tr {
    background: #fff
}

.table.table-custom thead th {
    border-top: 0;
    border-bottom: 1px solid rgba(45, 53, 65, 0.05)
}

.table.table-custom tfoot th {
    border-bottom: 0
}

.table.table-custom td {
    border-top: 1px solid #f3f4f6;
    border-bottom: 1px solid #f3f4f6
}

.table.table-custom td:last-child {
    border-right: 1px solid #f3f4f6;
    border-radius: 0 .1875rem .1875rem 0
}

.table.table-custom td:first-child {
    border-left: 1px solid #f3f4f6;
    border-radius: .1875rem 0 0 .1875rem
}

.table.table-custom tbody tr {
    background: #fff;
    border-radius: .1875rem
}

.table.table-custom tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.05)
}

.table.table-striped tbody tr:nth-of-type(odd) {
    background: rgba(255, 255, 255, 0.02)
}

.table.table-striped td {
    border: none
}

.card-wrapper {
    display: inline-block;
    perspective: 1000px;
    width: 100%
}

.card-wrapper .card {
    position: relative;
    cursor: pointer;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transform-style: preserve-3d
}

.card-wrapper .card .front,
.card-wrapper .card .back {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform: rotateX(0deg);
    background: #fff
}

.card-wrapper .card .front {
    z-index: 2
}

.card-wrapper .card.s-widget-top {
    height: 162px
}

.card-wrapper .card .back,
.card-wrapper.flip-right .card .back {
    transform: rotateY(180deg)
}

.card-wrapper:hover .card,
.card-wrapper.flip-right:hover .card {
    transform: rotateY(180deg)
}

.card-wrapper.flip-left .card .back {
    transform: rotateY(-180deg)
}

.card-wrapper.flip-left:hover .card {
    transform: rotateY(-180deg)
}

.card-wrapper.flip-up .card .back {
    transform: rotateX(180deg)
}

.card-wrapper.flip-up:hover .card {
    transform: rotateX(180deg)
}

.card-wrapper.flip-down .card .back {
    transform: rotateX(-180deg)
}

.card-wrapper.flip-down:hover .card {
    transform: rotateX(-180deg)
}

.card-wrapper.flip-diagonal-right .card .back {
    transform: rotate3d(1, 1, 0, 180deg)
}

.card-wrapper.flip-diagonal-right:hover .card {
    transform: rotate3d(1, 1, 0, 180deg)
}

.card-wrapper.flip-diagonal-left .card .back {
    transform: rotate3d(1, 1, 0, -180deg)
}

.card-wrapper.flip-diagonal-left:hover .card {
    transform: rotate3d(1, 1, 0, -180deg)
}

.card-wrapper.flip-inverted-diagonal-right .card .back {
    transform: rotate3d(-1, 1, 0, 180deg)
}

.card-wrapper.flip-inverted-diagonal-right:hover .card {
    transform: rotate3d(-1, 1, 0, 180deg)
}

.card-wrapper.flip-inverted-diagonal-left .card .back {
    transform: rotate3d(1, -1, 0, 180deg)
}

.card-wrapper.flip-inverted-diagonal-left:hover .card {
    transform: rotate3d(1, -1, 0, 180deg)
}

.note-editor.note-frame {
    border-color: #ced4da;
    background: #fff
}

.note-editor.note-frame .note-editing-area .note-editable {
    background: #fff;
    color: #494b52
}

.note-editor.note-frame .note-statusbar {
    background-color: #ced4da
}

.cke_chrome .cke_top {
    background: #f7f7f7
}

.cke_chrome .cke_toolgroup,
.cke_chrome a.cke_combo_button {
    background-color: #fff;
    box-shadow: none;
    background-image: none;
    border-color: #e2e4e7
}

.md-editor {
    border-color: #ced4da
}

.md-editor.active {
    border-color: #6290d0
}

.md-editor>textarea {
    background: #fff;
    border-color: #ced4da;
    color: #494b52
}

.md-editor>textarea:focus {
    background: transparent
}

.md-editor .md-footer,
.md-editor>.md-header {
    background: #ced4da
}

.md-editor>.md-preview {
    background: #fff;
    padding: 10px;
    border-color: #ced4da
}

.md-editor .md-controls {
    padding: 0;
    position: relative;
    top: 8px;
    right: 5px
}

.md-editor .md-controls .md-control {
    padding: 0
}

.md-editor .md-controls .md-control:hover {
    color: #e2e4e7
}

.page-loader-wrapper {
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #ebeeef
}

.page-loader-wrapper .loader {
    position: relative;
    top: calc(40% - 30px)
}

.setting-list li {
    padding: 8px 0;
    display: flex;
    align-items: center
}

.chat-widget li {
    list-style: none;
    width: 100%
}

.chat-widget li .avtar-pic {
    margin: 0 !important;
    vertical-align: top;
    float: left
}

.chat-widget li.left img {
    float: left
}

.chat-widget li.left .chat-info {
    background: rgba(45, 53, 65, 0.1);
    border-radius: 0 10px 10px 10px;
    float: left
}

.chat-widget li.left .chat-info:before {
    border-top: 0 solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid rgba(45, 53, 65, 0.1);
    left: -10px;
    top: 0
}

.chat-widget li.right {
    float: right
}

.chat-widget li.right img {
    float: right
}

.chat-widget li.right .chat-info {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    color: #77797c;
    border-radius: 10px 0 10px 10px
}

.chat-widget li.right .chat-info:before {
    border-top: 0 solid transparent;
    border-bottom: 10px solid transparent;
    right: -10px;
    top: 0
}

.chat-widget li .chat-info {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    width: calc(100% - 60px);
    padding: 10px;
    position: relative
}

.chat-widget li .chat-info:before {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: 12px
}

.chat-widget li .datetime {
    font-size: 12px;
    color: #96989c
}

.chat-widget li .message {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block
}

.chat-widget li img {
    height: 40px;
    width: 40px
}

.top_counter .icon {
    background: #f7f7f7;
    border-radius: .1875rem;
    float: left;
    width: 50px;
    height: 50px
}

.top_counter .icon i {
    line-height: 50px
}

.top_counter .content {
    height: 50px
}

.right_chat li {
    position: relative
}

.right_chat li.offline .status {
    background: #FFA117
}

.right_chat li.online .status {
    background: #5CB65F
}

.right_chat li.me .status {
    background: #36c2cf
}

.right_chat .media .media-object {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 35px
}

.right_chat .media .name {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    color: #393d42;
    margin: 0;
    line-height: 18px
}

.right_chat .media .message {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    color: #96989c
}

.right_chat .media .status {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    min-width: inherit;
    border: 3px solid #ebeeef;
    position: absolute;
    left: 23px;
    top: 30px;
    width: 11px;
    height: 11px
}

.maintenance {
    background: #17191c
}

.w_followers a:hover .btn {
    opacity: 1
}

.w_followers .btn {
    position: absolute;
    right: 0;
    top: 4px;
    opacity: 0
}

.todo_list .fancy-checkbox input[type="checkbox"]:checked+span {
    text-decoration: line-through
}

.todo_list li {
    position: relative;
    padding: 12px 0
}

.todo_list li .todo-delete {
    opacity: 0;
    position: absolute;
    right: 0
}

.todo_list li:hover .todo-delete {
    opacity: 1
}

.todo_list li.delete {
    display: none
}

.feeds_widget li a {
    display: flex;
    padding: 15px 25px;
    align-items: center
}

.feeds_widget li a:hover {
    background: rgba(45, 53, 65, 0.01)
}

.feeds_widget li .feeds-left {
    border-radius: .1875rem;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center
}

.feeds_widget li .feeds-body {
    width: calc(100% - 55px)
}

.feeds_widget li .feeds-body .title {
    color: #2D3541;
    font-weight: 400
}

.feeds_widget li .feeds-body>small {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    color: #57595d
}

.icon-in-bg {
    width: 55px;
    height: 55px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center
}

.small_state {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.w_summary {
    display: flex;
    align-items: center
}

.w_summary .s_chart {
    width: 70px
}

.w_summary .s_detail {
    width: calc(100% - 70px)
}

.w_summary .s_detail span {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline
}

.stamp {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
    display: inline-block;
    min-width: 2rem;
    height: 2rem;
    padding: 0 .25rem;
    line-height: 2rem
}

.stamp.stamp-md {
    min-width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem
}

.card-value {
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 3.4rem;
    height: 3.4rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.table-calendar td,
.table-calendar th {
    padding: 5px;
    border: 0
}

.table-calendar .table-calendar-link {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    background: #ced4da;
    color: #494b52;
    font-weight: 600;
    line-height: 2rem;
    min-width: calc(2rem + 2px);
    transition: .3s background, .3s color;
    position: relative
}

.table-calendar .table-calendar-link:before {
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background: #e25985;
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    left: .25rem;
    top: .25rem
}

.main-map {
    height: 550px
}

@media screen and (max-width: 1280px) {
    .main-map {
        height: 350px
    }
}

@media screen and (max-width: 768px) {
    .main-map {
        height: 200px
    }
}

.w_knob {
    display: flex;
    align-items: center
}

.invoice_list .table.table-custom thead th:before,
.invoice_list .table.table-custom thead th:after {
    display: none
}

.invoice_list .table.table-custom thead th.sorting_desc {
    color: #e25985
}

.invoice_list .table.table-custom thead th.sorting_asc {
    color: #5CB65F
}

.lightGallery img {
    opacity: 0.8
}

.lightGallery img:hover {
    opacity: 1
}

.tp-setting .float-right {
    font-size: 0
}

.row-xs {
    margin-left: -.25rem;
    margin-right: -.25rem
}

.row-xs .col,
.row-xs [class*=col-] {
    padding-left: .25rem;
    padding-right: .25rem
}

.team-info li {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    display: inline-block
}

.team-info li+li {
    margin-left: -10px
}

.team-info li img {
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    width: 35px;
    border: 2px solid #f7f7f7;
    box-shadow: 0px 2px 10px 0px rgba(45, 53, 65, 0.2)
}

.list-group li>.float-right {
    font-size: 0
}

.avtar-pic {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin-right: 10px;
    display: inline-block
}

.avtar-pic span {
    display: block;
    text-align: center;
    font-weight: 500
}

.avtar-pic.w35 {
    width: 35px;
    height: 35px;
    line-height: 35px
}

.avtar-pic.w40 {
    width: 40px;
    height: 40px;
    line-height: 40px
}

.avtar-pic.w50 {
    width: 50px;
    height: 50px;
    line-height: 50px
}

.card-chart-bg {
    background: #fff
}

.chatapp_list {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 250px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
    z-index: 7
}

.chatapp_body {
    margin-left: 250px;
    border-left: 1px solid #ced4da
}

@media screen and (max-width: 992px) {
    .chatapp_body {
        margin-right: 0
    }
}

@media screen and (max-width: 768px) {
    .chatapp_body {
        border: 0
    }
}

.chatapp_body .chat-header {
    padding: 15px 20px
}

@media screen and (max-width: 768px) {
    .chatapp_body .chat-header {
        padding-left: 0;
        padding-right: 0
    }
}

.chatapp_body .chat-header img {
    float: left;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 40px
}

.chatapp_body .chat-header .chat-about {
    float: left
}

.chatapp_body .chat-history {
    padding: 20px;
    border-top: 1px solid #ced4da
}

@media screen and (max-width: 1024px) {
    .chatapp_body .chat-history {
        height: 450px;
        overflow-y: auto
    }
}

@media screen and (max-width: 992px) {
    .chatapp_body .chat-history {
        height: 630px;
        overflow-y: auto
    }
}

@media screen and (max-width: 768px) {
    .chatapp_body .chat-history {
        padding-left: 0;
        padding-right: 0
    }
}

.chatapp_body .chat-history .message_data li {
    list-style: none;
    position: relative
}

.chatapp_body .chat-history .message_data li .data_time {
    color: #77797c;
    position: absolute;
    bottom: -25px
}

.chatapp_body .chat-history .message_data li .message {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    padding: 18px 20px;
    line-height: 26px;
    position: relative
}

.chatapp_body .chat-history .message_data li .message:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 5px
}

.chatapp_body .chat-history .message_data li .user_pix {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    width: 35px;
    height: 35px;
    opacity: 0.6
}

.chatapp_body .chat-history .message_data li .user_pix:hover {
    opacity: 1
}

.chatapp_body .chat-history .message_data li.left .user_pix {
    left: 0
}

.chatapp_body .chat-history .message_data li.left .data_time {
    left: 50px
}

.chatapp_body .chat-history .message_data li.left .message {
    background: #3C89DA;
    color: #f7f7f7;
    float: left;
    border-radius: 10px 10px 10px 0
}

.chatapp_body .chat-history .message_data li.left .message:after {
    bottom: 0;
    left: -10px;
    border-right-color: #3C89DA;
    border-bottom-color: #3C89DA
}

.chatapp_body .chat-history .message_data li.right .user_pix {
    right: 0
}

.chatapp_body .chat-history .message_data li.right .data_time {
    right: 50px
}

.chatapp_body .chat-history .message_data li.right .message {
    background: #f7f7f7;
    color: #57595d;
    float: right;
    border-radius: 10px 10px 0 10px
}

.chatapp_body .chat-history .message_data li.right .message:after {
    bottom: 0;
    right: -10px;
    border-left-color: #f7f7f7;
    border-bottom-color: #f7f7f7;
    margin-left: 0
}

.chatapp_body .chat-message {
    padding: 20px
}

@media screen and (max-width: 768px) {
    .chatapp_body .chat-message {
        padding-left: 0;
        padding-right: 0
    }
}

.chatapp_detail {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    width: 250px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    z-index: 7
}

@media screen and (max-width: 992px) {
    .chatapp_detail {
        display: none
    }
}

@media screen and (max-width: 767px) {
    .chatapp_list {
        height: 465px;
        width: 100%;
        overflow-x: auto;
        background: #fff;
        left: -400px;
        display: none
    }

    .chatapp_list.open {
        left: 0
    }

    .chatapp_body {
        margin: 0
    }

    .chatapp_body .chat-header {
        border-radius: 0.55rem 0.55rem 0 0
    }

    .chatapp_body .chat-history {
        height: 300px;
        overflow-x: auto
    }
}

.mail-inbox .mail-right {
    position: relative
}

.mail-inbox .mail-right .header {
    padding: 15px
}

.mail-inbox .mail-right h2 {
    line-height: 35px
}

.mail-inbox .mail-right .mail-action .fancy-checkbox input[type="checkbox"]+span:before {
    bottom: 0
}

.mail-inbox .mail-right .mail-action .pagination-email p {
    line-height: 30px
}

.mail-inbox .mail-right .mail-list ul li {
    background: rgba(45, 53, 65, 0.01);
    padding: 15px;
    width: 100%;
    position: relative;
    margin: 5px 0
}

.mail-inbox .mail-right .mail-list ul li:hover {
    background: rgba(255, 255, 255, 0.06)
}

.mail-inbox .mail-right .mail-list ul li.unread {
    position: relative
}

.mail-inbox .mail-right .mail-list ul li.unread::before {
    background: #de4848;
    position: absolute;
    width: 3px;
    height: 100%;
    content: '';
    left: 0;
    top: 0
}

.mail-inbox .mail-right .mail-list ul li .md-left,
.mail-inbox .mail-right .mail-list ul li .md-right {
    float: left
}

.mail-inbox .mail-right .mail-list ul li .md-left {
    max-width: 60px;
    width: 60px;
    position: relative;
    margin-top: 8px
}

@media screen and (max-width: 768px) {
    .mail-inbox .mail-right .mail-list ul li .md-left {
        width: 45px
    }
}

.mail-inbox .mail-right .mail-list ul li .md-left .mail-star {
    color: #494b52;
    position: absolute;
    right: 13px;
    top: 0
}

.mail-inbox .mail-right .mail-list ul li .md-left .mail-star.active {
    color: #FFA117
}

@media screen and (max-width: 768px) {
    .mail-inbox .mail-right .mail-list ul li .md-left .mail-star {
        display: none
    }
}

.mail-inbox .mail-right .mail-list ul li .md-right {
    position: relative;
    padding-right: 70px;
    width: calc(100% - 70px)
}

@media screen and (max-width: 768px) {
    .mail-inbox .mail-right .mail-list ul li .md-right {
        padding-right: 60px;
        width: calc(100% - 50px)
    }
}

.mail-inbox .mail-right .mail-list ul li .md-right span.time {
    position: absolute;
    top: 0;
    right: 0
}

.mail-inbox .mail-right .mail-list ul li .md-right img {
    float: left;
    width: 38px
}

.mail-inbox .mail-right .mail-list ul li .md-right p {
    width: calc(100% - 50px);
    float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.mail-inbox .mail-right .mail-detail-full .detail-header {
    padding: 10px 0;
    margin: 10px 0;
    border-bottom: 1px solid #ced4da
}

.mail-inbox .mail-right .mail-detail-full .detail-header .float-left img {
    max-width: 40px
}

.mobile-left {
    display: none
}

@media screen and (max-width: 991px) {
    .mail-inbox .mail-right .mail-action .btn {
        padding-left: 10px;
        padding-right: 10px
    }

    .mail-inbox .mail-right .mail-action .pagination-email p {
        display: none
    }
}

@media screen and (max-width: 767px) {
    .mobile-left {
        display: block;
        position: fixed;
        z-index: 9999;
        right: 15px;
        bottom: 15px
    }

    .mail-inbox .mail-left {
        position: absolute;
        left: 0;
        background-color: #fff;
        z-index: 7
    }

    .mail-inbox .mail-right .header {
        flex-direction: column
    }

    .mail-inbox .mail-right .header .ml-auto {
        margin-left: 0 !important;
        margin-top: 15px
    }

    .mail-detail-full a.mail-back {
        top: -60px
    }

    .media-body p span,
    .media-body p small {
        display: none
    }
}

.timeline {
    line-height: 1.4em;
    list-style: none;
    width: 100%
}

.timeline .timeline-item {
    position: relative
}

.timeline .timeline-info {
    letter-spacing: 3px;
    margin: 0 0 .5em 0;
    text-transform: uppercase;
    white-space: nowrap
}

.timeline .timeline-marker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15px
}

.timeline .timeline-marker:before {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background: #00bcd2;
    border: 3px solid transparent;
    content: "";
    display: block;
    height: 15px;
    position: absolute;
    top: 4px;
    left: 0;
    width: 15px;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out
}

.timeline .timeline-marker:after {
    content: "";
    width: 3px;
    background: rgba(45, 53, 65, 0.2);
    display: block;
    position: absolute;
    top: 24px;
    bottom: 0;
    left: 6px
}

.timeline-item:last-child .timeline .timeline-marker:after {
    content: none
}

.timeline .timeline-item:not(.period):hover .timeline-marker:before {
    background: transparent;
    border: 3px solid #00bcd2
}

.timeline .period {
    padding: 0
}

.timeline .period .timeline-info {
    display: none
}

.timeline .period .timeline-marker:before {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: transparent;
    content: "";
    width: 15px;
    height: auto;
    border: none;
    top: 0;
    bottom: 30px;
    position: absolute;
    border-top: 3px solid #57595d;
    border-bottom: 3px solid #57595d
}

.timeline .period .timeline-marker:after {
    content: "";
    height: 32px;
    top: auto
}

.timeline .period .timeline-content {
    padding: 40px 0 70px
}

@media (min-width: 768px) {

    .timeline.timeline-split .timeline,
    .timeline.timeline-centered .timeline {
        display: table
    }

    .timeline.timeline-split .timeline-item,
    .timeline.timeline-centered .timeline-item {
        display: table-row;
        padding: 0
    }

    .timeline.timeline-split .timeline-info,
    .timeline.timeline-centered .timeline-info,
    .timeline.timeline-split .timeline-marker,
    .timeline.timeline-centered .timeline-marker,
    .timeline.timeline-split .timeline-content,
    .timeline.timeline-centered .timeline-content,
    .timeline.timeline-split .period .timeline-info,
    .timeline.timeline-centered .period .timeline-info {
        display: table-cell;
        vertical-align: top
    }

    .timeline.timeline-split .timeline-marker,
    .timeline.timeline-centered .timeline-marker {
        position: relative
    }

    .timeline.timeline-split .timeline-content,
    .timeline.timeline-centered .timeline-content {
        padding-left: 30px
    }

    .timeline.timeline-split .timeline-info,
    .timeline.timeline-centered .timeline-info {
        padding-right: 30px
    }

    .timeline.timeline-split .period .timeline-title,
    .timeline.timeline-centered .period .timeline-title {
        position: relative;
        left: -45px
    }
}

@media (min-width: 992px) {

    .timeline.timeline-centered,
    .timeline.timeline-centered .timeline-item,
    .timeline.timeline-centered .timeline-info,
    .timeline.timeline-centered .timeline-marker,
    .timeline.timeline-centered .timeline-content {
        display: block;
        margin: 0;
        padding: 0
    }

    .timeline.timeline-centered .timeline-item {
        padding-bottom: 40px;
        overflow: hidden
    }

    .timeline.timeline-centered .timeline-marker {
        position: absolute;
        left: 50%;
        margin-left: -7.5px
    }

    .timeline.timeline-centered .timeline-info,
    .timeline.timeline-centered .timeline-content {
        width: 50%
    }

    .timeline.timeline-centered>.timeline-item:nth-child(odd) .timeline-info {
        float: left;
        text-align: right;
        padding-right: 30px
    }

    .timeline.timeline-centered>.timeline-item:nth-child(odd) .timeline-content {
        float: right;
        text-align: left;
        padding-left: 30px
    }

    .timeline.timeline-centered>.timeline-item:nth-child(even) .timeline-info {
        float: right;
        text-align: left;
        padding-left: 30px
    }

    .timeline.timeline-centered>.timeline-item:nth-child(even) .timeline-content {
        float: left;
        text-align: right;
        padding-right: 30px
    }

    .timeline.timeline-centered>.timeline-item.period .timeline-content {
        float: none;
        padding: 0;
        width: 100%;
        text-align: center
    }

    .timeline.timeline-centered .timeline-item.period {
        padding: 50px 0 90px
    }

    .timeline.timeline-centered .period .timeline-marker:after {
        height: 30px;
        bottom: 0;
        top: auto
    }

    .timeline.timeline-centered .period .timeline-title {
        left: auto
    }
}

.timeline.marker-outline .timeline-marker:before {
    background: transparent;
    border-color: #00bcd2
}

.timeline.marker-outline .timeline-item:hover .timeline-marker:before {
    background: #00bcd2
}

.fc .fc-toolbar {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    color: #212834;
    background: transparent
}

.fc .fc-toolbar h2 {
    color: #96989c;
    text-transform: uppercase
}

.fc .fc-toolbar .fc-today-button,
.fc .fc-toolbar .fc-state-default {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: transparent;
    color: #96989c;
    font-size: 15px;
    padding: 5px 18px;
    height: auto;
    border: none;
    box-shadow: none;
    text-shadow: none
}

.fc .fc-view-container .fc-event {
    color: #f7f7f7;
    border-radius: .1875rem;
    border: 0;
    padding: 3px 5px
}

.fc .fc-view-container .fc-event.fc-event-end:not(.fc-event-start) {
    border: 0 !important
}

.fc .fc-view-container .fc-event:focus,
.fc .fc-view-container .fc-event:hover,
.fc .fc-view-container .fc-event:active {
    z-index: 1000;
    color: #fff
}

.fc .fc-view-container .fc-event:focus .fc-overlay,
.fc .fc-view-container .fc-event:hover .fc-overlay,
.fc .fc-view-container .fc-event:active .fc-overlay {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block
}

.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header,
.fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-widget-header,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr th.fc-widget-header,
.fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-widget-header {
    border: 1px solid #ced4da;
    padding: 10px;
    color: #393d42
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-widget-content,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-widget-content {
    border: 0;
    border-bottom: 1px solid #ced4da;
    border-right: 1px solid #ced4da
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-widget-content.fc-state-highlight,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-widget-content.fc-state-highlight {
    background: #fff
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-day-number,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-day-number {
    padding: 5px 10px;
    margin: 5px;
    color: #212834
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-day-number.fc-state-highlight,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-day-number.fc-state-highlight {
    background-color: #E15858;
    color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    padding: 4px 6px;
    float: right
}

.fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td:first-child.fc-widget-content,
.fc .fc-view-container .fc-view.fc-agenda>table tbody tr td:first-child.fc-widget-content {
    border-left: 1px solid #ced4da
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-body .fc-row .fc-bg,
.fc .fc-view-container .fc-view.fc-agenda .fc-body .fc-row .fc-bg {
    border-bottom: 1px solid #ced4da
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-body .fc-row:last-of-type .fc-bg,
.fc .fc-view-container .fc-view.fc-agenda .fc-body .fc-row:last-of-type .fc-bg {
    border-bottom: 0
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-allday .fc-agenda-axis,
.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-allday .fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-axis,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-gutter {
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    background-color: #e2e4e7;
    color: #212834;
    font-weight: 300;
    padding: 10px 5px
}

.fc .fc-view-container .fc-view.fc-basic-view>table>tbody>tr>td.fc-state-highlight,
.fc .fc-view-container .fc-view.fc-agenda>table>tbody>tr>td.fc-state-highlight {
    background: #e2e4e7
}

.fc .fc-view-container .fc-view.fc-basic-view>table>tbody>tr>td.fc-sat,
.fc .fc-view-container .fc-view.fc-agenda>table>tbody>tr>td.fc-sat {
    border-right: 0
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr {
    line-height: 35px
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr th,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr th {
    border: 0;
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    background-color: #e2e4e7;
    color: #212834;
    font-size: 12px;
    font-weight: 100;
    padding-top: 10px
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr td.fc-widget-content,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr td.fc-widget-content {
    border-bottom: 1px solid #ced4da;
    line-height: 50px
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr td.fc-widget-content:last-child,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr td.fc-widget-content:last-child {
    border-right: 0
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr.fc-minor td.fc-widget-content,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr.fc-minor td.fc-widget-content {
    border-bottom: 1px solid #ced4da
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-divider,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-divider {
    border: 1px solid #ced4da
}

.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-divider .fc-agenda-divider-inner,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-divider .fc-agenda-divider-inner {
    background-color: #e2e4e7;
    height: 3px
}

.fc .fc-view-container .fc-list-view .fc-list-item {
    color: #e2e4e7
}

.fc .fc-view-container .fc-other-month {
    background: #ebeeef
}

.fc .fc-view-container .fc-basic-view .fc-day-number,
.fc .fc-view-container .fc-basic-view .fc-week-number {
    float: left;
    padding: 10px 0 5px 10px
}

.fc .fc-popover {
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border: none;
    padding: 10px;
    border-radius: .55rem
}

.fc .fc-popover .fc-event-container {
    padding: 0
}

.fc .fc-popover .fc-event {
    padding: 4px 8px;
    margin: 2px 0px
}

.fc .fc-agendaWeek-view .fc-head .fc-widget-header span,
.fc .fc-agendaDay-view .fc-head .fc-widget-header span,
.fc .fc-listWeek-view .fc-head .fc-widget-header span {
    padding: 10px;
    display: inline-block
}

.fc .fc-divider {
    display: none
}

.fc .fc-scroller {
    height: auto !important;
    overflow: unset !important
}

.fc-unthemed th {
    background: #ced4da
}

.fc-unthemed td,
.fc-unthemed th {
    border-color: #ced4da
}

.fc-unthemed .fc-divider {
    background: #ced4da
}

@media screen and (max-width: 767px) {
    .fc-toolbar .fc-right {
        display: none
    }

    .fc-toolbar .fc-left {
        width: 100%;
        margin-bottom: 15px
    }

    .fc .fc-toolbar .fc-today-button {
        float: right
    }
}

.pricing {
    color: #57595d
}

.pricing:hover .plan-img img {
    background: #007FFF
}

.pricing li {
    list-style: none;
    padding: 8px
}

.pricing .plan-img img {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background: rgba(255, 255, 255, 0.03);
    max-width: 180px
}

.pricing h3 span {
    vertical-align: top
}

.pricing.active h3 {
    color: #007FFF
}

.invoice1 .logo,
.invoice1 .clientlogo {
    float: left;
    height: 70px;
    width: 70px
}

.invoice1 .info {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block;
    float: left
}

.invoice1 .invoice-top .title {
    float: right
}

.invoice1 .invoice-mid .info {
    width: calc(100% - 90px)
}

.invoice .table tr td {
    vertical-align: middle
}

.news .card-title a {
    color: rgba(255, 255, 255, 0.6)
}

.news .card-title a:hover {
    color: #3C89DA
}

.news .card-columns {
    column-count: 3
}

@media screen and (max-width: 992px) {
    .news .card-columns {
        column-count: 2
    }
}

@media screen and (max-width: 767px) {
    .news .card-columns {
        column-count: 1
    }
}

.n_category .sub_n_category {
    position: absolute;
    left: 15px;
    top: 15px;
    padding: 3px 10px;
    border-radius: .1875rem
}

.n_category .sub_n_category.p_right {
    left: auto;
    right: 15px
}

.social .profile-header {
    border-radius: .1875rem;
    background: #2D3541;
    color: #fff;
    padding: 20px;
    position: relative;
    overflow: hidden
}

@media screen and (max-width: 767px) {
    .social .profile-header {
        display: block !important
    }

    .social .profile-header .d-flex {
        margin-bottom: 20px
    }
}

.social .profile-header img {
    width: 70px
}

.w_social3 .card {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-weight: 400;
    color: #494b52;
    background: #fff;
    padding: 22px;
    border-top: 1px solid rgba(255, 255, 255, 0.09)
}

.w_social3 .card .fa {
    font-size: 30px
}

.w_social3 .text {
    margin-top: 0 !important
}

.w_social3 .facebook-widget .icon i {
    color: #3b5998
}

.w_social3 .facebook-widget:hover {
    background: #3b5998
}

.w_social3 .facebook-widget:hover .icon i {
    color: #fff
}

.w_social3 .google-widget .icon i {
    color: #d34836
}

.w_social3 .google-widget:hover {
    background: #d34836
}

.w_social3 .google-widget:hover .icon i {
    color: #fff
}

.w_social3 .twitter-widget .icon i {
    color: #0084b4
}

.w_social3 .twitter-widget:hover {
    background: #0084b4
}

.w_social3 .twitter-widget:hover .icon i {
    color: #fff
}

.w_social3 .instagram-widget .icon i {
    color: #fb3958
}

.w_social3 .instagram-widget:hover {
    background: #fb3958
}

.w_social3 .instagram-widget:hover .icon i {
    color: #fff
}

.member-card img {
    width: 80px;
    box-shadow: 0px 10px 25px 0px rgba(45, 53, 65, 0.3)
}

.member-card .text-pink {
    display: block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: block
}

.theme-blue ::selection {
    color: #fff;
    background: #36c2cf
}

.theme-blue .auth-main {
    background: #36c2cf
}

.theme-blue .sidebar-nav .metismenu>li.active>a,
.theme-blue .sidebar-nav .metismenu>li a:hover {
    background: #36c2cf;
    color: #fff
}

.theme-blue .sidebar-nav .metismenu>li ul .active a {
    color: #36c2cf
}

.theme-blue .sidebar-nav .metismenu ul a:hover::before {
    background: #36c2cf
}

.theme-blue .form-control:focus {
    color: #36c2cf
}

.theme-blue .fancy-checkbox input[type="checkbox"]:checked+span:before {
    color: #36c2cf;
    border-color: #36c2cf
}

.theme-blue .chat-widget li.right .chat-info {
    background: #36c2cf;
    color: #fff
}

.theme-blue .chat-widget li.right .chat-info:before {
    border-left: 10px solid #36c2cf
}

.theme-blue .wizard>.steps .current a {
    background: #36c2cf
}

.theme-blue .wizard>.steps .done a {
    background: #77797c
}

.theme-blue .pagination>li>a {
    color: #36c2cf
}

.theme-blue .pagination>li>a:hover {
    background: #36c2cf;
    border-color: #36c2cf;
    color: #fff
}

.theme-blue .pagination>li>span {
    color: #36c2cf
}

.theme-blue .page-item.active .page-link {
    background-color: #36c2cf;
    border-color: #36c2cf
}

.theme-blue .switch input:checked+.slider {
    background-color: #36c2cf
}

.theme-blue .switch input:focus+.slider {
    box-shadow: 0 0 1px #36c2cf
}

.theme-blue .navbar-fixed-top #navbar-search .form-control:focus,
.theme-blue .navbar-fixed-top #navbar-search .form-control:active {
    background: #fff;
    color: #36c2cf
}

.theme-blue .navbar-fixed-top {
    border-color: #b3c98c
}

.theme-blue .sidebar_icon {
    background: #36c2cf
}

.theme-blue #left-sidebar {
    border-color: #36c2cf
}

.theme-blue .navbar-nav .dropdown-menu li.header {
    background: #36c2cf
}

.theme-cyan ::selection {
    color: #fff;
    background: #36c2cf
}

.theme-cyan .auth-main {
    background: #36c2cf
}

.theme-cyan .sidebar-nav .metismenu>li.active>a,
.theme-cyan .sidebar-nav .metismenu>li a:hover {
    background: #36c2cf;
    color: #fff
}

.theme-cyan .sidebar-nav .metismenu>li ul .active a {
    color: #36c2cf
}

.theme-cyan .sidebar-nav .metismenu ul a:hover::before {
    background: #36c2cf
}

.theme-cyan .form-control:focus {
    color: #36c2cf
}

.theme-cyan .fancy-checkbox input[type="checkbox"]:checked+span:before {
    color: #36c2cf;
    border-color: #36c2cf
}

.theme-cyan .chat-widget li.right .chat-info {
    background: #36c2cf;
    color: #fff
}

.theme-cyan .chat-widget li.right .chat-info:before {
    border-left: 10px solid #36c2cf
}

.theme-cyan .wizard>.steps .current a {
    background: #36c2cf
}

.theme-cyan .wizard>.steps .done a {
    background: #77797c
}

.theme-cyan .pagination>li>a {
    color: #36c2cf
}

.theme-cyan .pagination>li>a:hover {
    background: #36c2cf;
    border-color: #36c2cf;
    color: #fff
}

.theme-cyan .pagination>li>span {
    color: #36c2cf
}

.theme-cyan .page-item.active .page-link {
    background-color: #36c2cf;
    border-color: #36c2cf
}

.theme-cyan .switch input:checked+.slider {
    background-color: #36c2cf
}

.theme-cyan .switch input:focus+.slider {
    box-shadow: 0 0 1px #36c2cf
}

.theme-cyan .navbar-fixed-top #navbar-search .form-control:focus,
.theme-cyan .navbar-fixed-top #navbar-search .form-control:active {
    background: #fff;
    color: #36c2cf
}

.theme-cyan .navbar-fixed-top {
    border-color: #36c2cf
}

.theme-cyan .sidebar_icon {
    background: #36c2cf
}

.theme-cyan #left-sidebar {
    border-color: #36c2cf
}

.theme-cyan .navbar-nav .dropdown-menu li.header {
    background: #36c2cf
}

.theme-green ::selection {
    color: #fff;
    background: #5CB65F
}

.theme-green .auth-main {
    background: #5CB65F
}

.theme-green .sidebar-nav .metismenu>li.active>a,
.theme-green .sidebar-nav .metismenu>li a:hover {
    background: #5CB65F;
    color: #fff
}

.theme-green .sidebar-nav .metismenu>li ul .active a {
    color: #5CB65F
}

.theme-green .sidebar-nav .metismenu ul a:hover::before {
    background: #5CB65F
}

.theme-green .form-control:focus {
    color: #5CB65F
}

.theme-green .fancy-checkbox input[type="checkbox"]:checked+span:before {
    color: #5CB65F;
    border-color: #5CB65F
}

.theme-green .chat-widget li.right .chat-info {
    background: #5CB65F;
    color: #fff
}

.theme-green .chat-widget li.right .chat-info:before {
    border-left: 10px solid #5CB65F
}

.theme-green .wizard>.steps .current a {
    background: #5CB65F
}

.theme-green .wizard>.steps .done a {
    background: #77797c
}

.theme-green .pagination>li>a {
    color: #5CB65F
}

.theme-green .pagination>li>a:hover {
    background: #5CB65F;
    border-color: #5CB65F;
    color: #fff
}

.theme-green .pagination>li>span {
    color: #5CB65F
}

.theme-green .page-item.active .page-link {
    background-color: #5CB65F;
    border-color: #5CB65F
}

.theme-green .switch input:checked+.slider {
    background-color: #5CB65F
}

.theme-green .switch input:focus+.slider {
    box-shadow: 0 0 1px #5CB65F
}

.theme-green .navbar-fixed-top #navbar-search .form-control:focus,
.theme-green .navbar-fixed-top #navbar-search .form-control:active {
    background: #fff;
    color: #5CB65F
}

.theme-green .navbar-fixed-top {
    border-color: #5CB65F
}

.theme-green .sidebar_icon {
    background: #5CB65F
}

.theme-green #left-sidebar {
    border-color: #5CB65F
}

.theme-green .navbar-nav .dropdown-menu li.header {
    background: #5CB65F
}

.theme-orange ::selection {
    color: #fff;
    background: #FFA117
}

.theme-orange .auth-main {
    background: #FFA117
}

.theme-orange .sidebar-nav .metismenu>li.active>a,
.theme-orange .sidebar-nav .metismenu>li a:hover {
    background: #FFA117;
    color: #fff
}

.theme-orange .sidebar-nav .metismenu>li ul .active a {
    color: #FFA117
}

.theme-orange .sidebar-nav .metismenu ul a:hover::before {
    background: #FFA117
}

.theme-orange .form-control:focus {
    color: #FFA117
}

.theme-orange .fancy-checkbox input[type="checkbox"]:checked+span:before {
    color: #FFA117;
    border-color: #FFA117
}

.theme-orange .chat-widget li.right .chat-info {
    background: #FFA117;
    color: #fff
}

.theme-orange .chat-widget li.right .chat-info:before {
    border-left: 10px solid #FFA117
}

.theme-orange .wizard>.steps .current a {
    background: #FFA117
}

.theme-orange .wizard>.steps .done a {
    background: #77797c
}

.theme-orange .pagination>li>a {
    color: #FFA117
}

.theme-orange .pagination>li>a:hover {
    background: #FFA117;
    border-color: #FFA117;
    color: #fff
}

.theme-orange .pagination>li>span {
    color: #FFA117
}

.theme-orange .page-item.active .page-link {
    background-color: #FFA117;
    border-color: #FFA117
}

.theme-orange .switch input:checked+.slider {
    background-color: #FFA117
}

.theme-orange .switch input:focus+.slider {
    box-shadow: 0 0 1px #FFA117
}

.theme-orange .navbar-fixed-top #navbar-search .form-control:focus,
.theme-orange .navbar-fixed-top #navbar-search .form-control:active {
    background: #fff;
    color: #FFA117
}

.theme-orange .navbar-fixed-top {
    border-color: #FFA117
}

.theme-orange .sidebar_icon {
    background: #FFA117
}

.theme-orange #left-sidebar {
    border-color: #FFA117
}

.theme-orange .navbar-nav .dropdown-menu li.header {
    background: #FFA117
}

.theme-blush ::selection {
    color: #fff;
    background: #e25985
}

.theme-blush .auth-main {
    background: #e25985
}

.theme-blush .sidebar-nav .metismenu>li.active>a,
.theme-blush .sidebar-nav .metismenu>li a:hover {
    background: #e25985;
    color: #fff
}

.theme-blush .sidebar-nav .metismenu>li ul .active a {
    color: #e25985
}

.theme-blush .sidebar-nav .metismenu ul a:hover::before {
    background: #e25985
}

.theme-blush .form-control:focus {
    color: #e25985
}

.theme-blush .fancy-checkbox input[type="checkbox"]:checked+span:before {
    color: #e25985;
    border-color: #e25985
}

.theme-blush .chat-widget li.right .chat-info {
    background: #e25985;
    color: #fff
}

.theme-blush .chat-widget li.right .chat-info:before {
    border-left: 10px solid #e25985
}

.theme-blush .wizard>.steps .current a {
    background: #e25985
}

.theme-blush .wizard>.steps .done a {
    background: #77797c
}

.theme-blush .pagination>li>a {
    color: #e25985
}

.theme-blush .pagination>li>a:hover {
    background: #e25985;
    border-color: #e25985;
    color: #fff
}

.theme-blush .pagination>li>span {
    color: #e25985
}

.theme-blush .page-item.active .page-link {
    background-color: #e25985;
    border-color: #e25985
}

.theme-blush .switch input:checked+.slider {
    background-color: #e25985
}

.theme-blush .switch input:focus+.slider {
    box-shadow: 0 0 1px #e25985
}

.theme-blush .navbar-fixed-top #navbar-search .form-control:focus,
.theme-blush .navbar-fixed-top #navbar-search .form-control:active {
    background: #fff;
    color: #e25985
}

.theme-blush .navbar-fixed-top {
    border-color: #e25985
}

.theme-blush .sidebar_icon {
    background: #e25985
}

.theme-blush #left-sidebar {
    border-color: #e25985
}

.theme-blush .navbar-nav .dropdown-menu li.header {
    background: #e25985
}

.recent-activities .item {
    padding: 0 15px;
    border-bottom: 1px solid #eee
}

.recent-activities .item div[class*='col-'] {
    padding: 15px
}

.recent-activities h5 {
    font-weight: 400;
    color: #333
}

.recent-activities p {
    font-size: 0.75em;
    color: #999
}

.recent-activities .icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #f5f5f5;
    text-align: center;
    display: inline-block
}

.recent-activities .date {
    font-size: 0.75em;
    color: #999;
    padding: 10px
}

.recent-activities .date-holder {
    padding: 0 !important;
    border-right: 1px solid #eee
}


.card-close {
    position: absolute;
    top: 15px;
    right: 15px
}

.card-close .dropdown-toggle {
    color: #999;
    background: none;
    border: none
}

.card-close .dropdown-toggle:after {
    display: none
}

.card-close .dropdown-menu {
    border: none;
    min-width: auto;
    font-size: 0.9em;
    border-radius: 0;
    -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1)
}

.card-close .dropdown-menu a {
    color: #999 !important
}

.card-close .dropdown-menu a:hover {
    background: #796AEE;
    color: #fff !important
}

.card-close .dropdown-menu a i {
    margin-right: 10px;
    -webkit-transition: none;
    transition: none
}